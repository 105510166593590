import Cart from '@Models/Cart';
import Customer from '@Models/Customer';
import Address from '@Models/Address';
import CreditCard from '@Models/CreditCard';
import Payment from '@Models/Payment';
import { convertToCurrency } from '@Lib/utils';
import Fees from './Fees';

class Order {
  constructor(attribs = {}) {
    this.placed = attribs.placed;
    this.orderId = attribs.orderId;
    this.cart = attribs.cart;
    this.customer = attribs.customer;
    this.shippingAddress = attribs.shippingAddress;
    this.creditCard = attribs.creditCard;
    this.payment = attribs.payment;
    this.fees = attribs.fees;
  }

  // No complementary `serialize` method is used here, as we only need to
  // construct the Order to send it to the API. Use `orderParams` instead.
  static deserialize(attribs = {}) {
    const {
      orderId,
      cart,
      customer,
      payment,
      creditCard,
      shippingAddress,
      fees,
    } = attribs;
    return new Order({
      orderId,
      cart: Cart.deserialize(cart),
      customer: Customer.deserialize(customer),
      shippingAddress: Address.deserialize(shippingAddress),
      creditCard: CreditCard.deserialize(creditCard),
      payment: Payment.deserialize(payment),
      fees: Fees.deserialize(fees),
    });
  }

  get hasItems() {
    return this.cart.hasItems;
  }

  get hasShipping() {
    return !!this.fees.shippingInCents;
  }

  get hasTax() {
    return !!this.fees.taxInCents;
  }

  // Will add on other charges as they come.
  get grandTotalInCents() {
    return this.cart.grandTotalInCents + this.fees.taxInCents;
  }

  get grandTotal() {
    return this.grandTotalInCents / 100;
  }

  get grandTotalAsCurrency() {
    return convertToCurrency(this.grandTotal);
  }
}

export default Order;
