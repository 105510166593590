import { isFormInput } from '@Lib/utils';
import { findImageUrl } from '@Lib/ImageUrlFinder';

const FINDER_SCHEMES = {
  image: ['imageValue'],
  quantity: ['dataValue', 'formInputValue'],
  label: ['labelValue', 'ariaValue', 'contentValue'],
  variantName: ['ariaValue', 'labelValue', 'dataValue'],
  option: ['formInputValue'],
  priceModifier: ['dataValue'],
  additionalCheckoutHTML: ['dataValue', 'htmlValue']
};

const DEFAULT_FINDER_SCHEME = [
  'dataValue',
  'formInputValue',
  'ariaValue',
  'contentValue',
];

// Find values on an element.
// Uses cascading finder schemes to find the value we want.
// The schemes are used to determine the priority by which we look for values.
class ValueFinder {
  /*
  @param (element) elem: the HTML element to parse
  @param (str) key: the cgn-data-attr value
  */
  constructor(elem, key) {
    this.elem = elem;
    this.key = key;
  }

  _finderScheme() {
    return FINDER_SCHEMES[this.key] || DEFAULT_FINDER_SCHEME;
  }

  findValue() {
    if (!this.elem) return undefined;

    let value = null;
    for (let finder of this._finderScheme()) {
      value = this[finder];
      if (value !== undefined) break;
    }
    return value;
  }

  // Return the value of an attribute on this element.
  _attributeValue(attribute) {
    return this.elem.getAttribute(attribute);
  }

  // Check if this element has the `data-cgn-attr` that we are expecting.
  // If it does, return the value.
  get dataValue() {
    if (this.elem.dataset.cgnAttr !== this.key) return undefined;

    return this.elem.dataset.cgnValue;
  }

  get contentValue() {
    const text = this.elem.textContent;
    if (!text) return '';

    return text.replace(/[\n\r]+/g, '').trim();
  }

  get srcValue() {
    return this._attributeValue('src');
  }

  get imageValue() {
    return findImageUrl(this.elem);
  }

  // Return the form input `value` if this is a form input.
  get formInputValue() {
    if (!isFormInput(this.elem)) return undefined;

    return this.elem.value;
  }

  // Look for an associated label with this element and return it's textContent.
  get labelValue() {
    if (!isFormInput(this.elem) || !this.elem.labels?.length) return undefined;

    return this.elem.labels[0].innerText || undefined;
  }

  get ariaValue() {
    return this._attributeValue('aria-label') || undefined;
  }

  // Sometimes, we want to pull in the entire html of an element. This returns
  // the innerHTML of that element.
  get htmlValue() {
    const html = this.elem.innerHTML?.trim();
    if (!html) return '';

    return html;
  }
}

export default ValueFinder;
