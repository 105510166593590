<script>
  import CartiganInput from "@Components/Input/CartiganInput.svelte";
  import ValidationMapper from '@Lib/ValidationMapper'
  import { getAssetURL } from '@Lib/utils/url'
  import { storage } from '@Lib/StorageWrangler';
  import { creditCard, payment, shippingAddress, order } from 'src/store';
  import { imask } from '@imask/svelte';
  import { onMount } from 'svelte';
  import {
    creditCardMask,
    expirationMask,
    securityCodeMask
  } from './masks';
  import ErrorDisplay from '../ErrorDisplay.svelte';
  import { PANELS_KEYS } from '@Lib/panelManager';
  import CreditCardTokenService from '@/services/CreditCardTokenService';
  import PlacerService from '@/services/PlacerService';
  import { setCompletedAndAdvance } from '@Lib/panelManager';
  import clsx from 'clsx';

  let errors;
  let showSpinner = false;
  let advanceButtonEnabled;
  let showCCSummary;
  let placeOrderFunc;
  let loadingText = 'Validating Card...';
  let formWasCompleted = false;

  const VALIDATION_MAPPERS = {
    cardNumber: new ValidationMapper({model: $creditCard, prop: "number"}),
    cardExpiration: new ValidationMapper({ model: $creditCard, prop: "expiration" }),
    cardBillingName: new ValidationMapper({ model: $creditCard, prop: "billingName" }),
    cardPostalCode: new ValidationMapper({ model: $creditCard, prop: "postalCode" }),
    cardCvv: new ValidationMapper({ model: $creditCard, prop: "verificationCode" }),
  };

  // These handlers will write the data to the credit card on input.
  const CARD_MASKED_FIELD_HANDLERS = {
    cardNumber: (maskRef) => {
      $creditCard.number = maskRef.unmaskedValue;
    },
    cardExpiration: (maskRef) => {
      const [month, year] = maskRef.unmaskedValue.split('/');
      if (month) { $creditCard.expirationMonth = month; }
      if (year) { $creditCard.expirationYear = year.slice(-2);}
    },
    cardCvv: (maskRef) => {
      $creditCard.verificationCode = maskRef.value;
    },
  };

  const allElements = () => {
    const mappers = Object.values(VALIDATION_MAPPERS);
    return mappers.map((vMapper) => vMapper.elem);
  }

  const focusNextCardField = () => {
    const mappers = Object.values(VALIDATION_MAPPERS);
    const nextMapper = mappers.find((mapper) => {
      return mapper.isBlank() || mapper.isInvalid();
    });
    if (!nextMapper) return;

    nextMapper.elem.focus();
  };

  // Will enable the button to advance checkout. Will also display errors if
  // they exist.
  const setFormFeedback = () => {
    if ($creditCard.isValid()) {
      advanceButtonEnabled = true;
      errors = null;
    } else if (formWasCompleted && $creditCard.errors.length) {
      errors = $creditCard.errors;
      advanceButtonEnabled = false;
    }
  }

  // We only want to set the FormFeedback if the form was ever completed.
  const checkModificationAfterFormCompleted = () => {
    if (formWasCompleted) { setFormFeedback() }
  }

  // Responsible for handing off to the appropriate input handler.
  // The handler will then write the data to the model on input.
  // Effectively the same thing as binding the value, but with the option to
  // use a mask and get the mask value.
  const handleMaskedFieldInput = (elem, { detail: maskRef }) => {
    maskRef.updateValue();
    const handler = CARD_MASKED_FIELD_HANDLERS[elem.id];
    if (!handler) return;

    handler(maskRef);
  };

  // Merely checks if all fields have a value. Does not check if the fields are valid.
  const isFormComplete = () => {
    const elems = allElements();
    return elems.every((elem) => elem.value);
  };

  // Focus the next blank field in the form. Check if the card is valid enough
  // to be processed. If it is, then enable the place order button.
  const handleFieldComplete = (focusNext = true) => {
    creditCard.save()
    if (focusNext) focusNextCardField();
    formWasCompleted = isFormComplete();
    setFormFeedback()
  };

  // Set the value of the model prop and the element itself.
  const setElementValue = (elementName, val, asDefault=false) => {
    const mapper = VALIDATION_MAPPERS[elementName];
    const elem = mapper.elem
    const modelProp = mapper.prop
    if(asDefault && elem.value) { return } // Don't overwrite value if for default

    if (elem){ elem.value = val; }
  }

  /*
  Set the billing name and the postal code from the shipping address if not
  already set in the CreditCard.
  */
  const setDefaultCardInfo = () => {
    if(!$creditCard.billingName) $creditCard.billingName = $shippingAddress.recipientName;
    if(!$creditCard.postalCode) $creditCard.postalCode = $shippingAddress.zipCode;
  };

  /*
  Because of the mask, we can't bind the value of the element to a value of
  the property on the $creditCard. So we have to manually set the value and
  suffer with an Imask warning in the event that credit caerd has been modified
  and we have traveled away from the panel. We don't want to lose that data.
  */
  const setCurrentCardInfo = () => {
    if ($creditCard.number) { setElementValue('cardNumber', $creditCard.number) }
    if ($creditCard.expirationMonth && $creditCard.expirationYear) {
      setElementValue('cardExpiration', `${$creditCard.expirationMonth}/${$creditCard.expirationYear}`)
    }
    if ($creditCard.verificationCode) {
      setElementValue('cardCvv', $creditCard.verificationCode)
    }
  }

  const resetPaymentData = () => {
    advanceButtonEnabled = false;
    creditCard.reset();
    $payment.forceRenewToken = true;
    payment.reset();
    setDefaultCardInfo();
  };

  const advanceCheckout = async () => {
    loadingText = 'Placing order...';
    showSpinner = true;

    const placer = new PlacerService();
    const {
      success,
      body: responseBody,
      errors: responseErrors,
    } = await placer.place($order);
    showSpinner = false;
    if (success) {
      storage.savePlacedOrder({
        orderId: responseBody.order.order_id,
        customer: responseBody.order.customer,
      });
      setCompletedAndAdvance(PANELS_KEYS.CheckoutPayment);
      return;
    }

    errors = responseErrors;
  };

  const getPaymentToken = async () => {
    showSpinner = true;
    document.activeElement.blur();
    const tokenService = new CreditCardTokenService();
    const {
      success,
      payment: responsePayment,
      errors: responseErrors,
    } = await tokenService.getToken($creditCard);
    showSpinner = false;
    if (success) {
      $payment = responsePayment;
      creditCard.save();
      payment.save();
      await advanceCheckout();
      return;
    }

    errors = [...$creditCard.errors, ...responseErrors].filter(Boolean);
  };

  onMount(() => {
    setDefaultCardInfo();
    setCurrentCardInfo();
    handleFieldComplete();
  });

  $: {
    showCCSummary = $payment.isChargeable || !$payment.shouldFetchToken;
  }

  $: {
    if ($payment.isChargeable) {
      advanceButtonEnabled = true;
      placeOrderFunc = advanceCheckout;
    } else {
      placeOrderFunc = getPaymentToken;
    }
  }
</script>

{#if showSpinner}
  <div
    class="spinner-area tw-absolute tw-h-full tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-z-10"
  >
    <div class="loader-text tw-mb-3">{loadingText}</div>
    <div class="cgn-loader" />
  </div>
{/if}
<ErrorDisplay {errors} />
<div class="cgn-credit-card-form">
  <div class="tw-flex tw-pt-6 tw-pb-3">
    <h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">Payment</h3>
    <img
      class="tw-ml-auto"
      src={`${getAssetURL('images/credit-cards.svg')}`}
      alt="credit-cards"
    />
  </div>
  {#if showCCSummary}
    <div class="tw-flex tw-p-3 infoBox">
      {$creditCard.brand} ending in {$creditCard.last4}
      <button
        class="tw-ml-auto tw-self-baseline tw-underline"
        on:click={resetPaymentData}
      >
        Edit
      </button>
    </div>
  {:else}
    <div class={clsx(showSpinner && 'disabledForm tw-opacity-50')}>
      <div class="tw-flex tw-border tw-border-blue-50 tw-placeholder-blue-50 tw-text-sm sm:tw-text-md">
        <input
          id="cardNumber"
          pattern="\d*"
          data-testid="cardNumber"
          placeholder="Card Number"
          class="tw-py-5 tw-px-6 tw-placeholder-blue-50 tw-w-full tw-outline-none"
          autocomplete="cc-number"
          bind:this={VALIDATION_MAPPERS.cardNumber.elem}
          use:imask={creditCardMask}
          on:input={() => checkModificationAfterFormCompleted()}
          on:accept={(arg) => handleMaskedFieldInput(VALIDATION_MAPPERS.cardNumber.elem, arg)}
          on:complete={handleFieldComplete}
        />

        <input
          id="cardExpiration"
          pattern="\d*"
          data-testid="cardExpiration"
          placeholder="Exp. Date"
          class="tw-py-5 tw-px-3 tw-placeholder-blue-50 tw-w-6/12 sm:tw-w-5/12 tw-outline-none"
          autocomplete="cc-exp"
          bind:this={VALIDATION_MAPPERS.cardExpiration.elem}
          use:imask={expirationMask}
          on:input={() => checkModificationAfterFormCompleted()}
          on:accept={(arg) =>
            handleMaskedFieldInput(VALIDATION_MAPPERS.cardExpiration.elem, arg)}
          on:complete={handleFieldComplete}
        />
      </div>
      <input
        class="tw-mt-3 tw-py-5 tw-px-6 tw-placeholder-blue-50 tw-border tw-border-blue-50 tw-w-full"
        id="cardBillingName"
        placeholder="Name on card"
        bind:this={VALIDATION_MAPPERS.cardBillingName.elem}
        bind:value={$creditCard.billingName}
        data-ref="cardBillingName"
        data-testid="cardBillingName"
        autocomplete="cc-name"
        on:input={() => checkModificationAfterFormCompleted()}
        on:blur={() => handleFieldComplete(false)}
      />
      <div class="tw-flex tw-mt-3">
        <input
          id="cardPostalCode"
          pattern="\d*"
          placeholder="ZIP Code"
          class="tw-py-5 tw-px-6 tw-placeholder-blue-50 tw-border tw-border-blue-50 tw-w-6/12"
          data-testid="cardPostalCode"
          autocomplete="postal-code"
          bind:this={VALIDATION_MAPPERS.cardPostalCode.elem}
          bind:value={$creditCard.postalCode}
          on:input={() => checkModificationAfterFormCompleted()}
          on:blur={() => handleFieldComplete(false)}
        />
        <input
          id="cardCvv"
          pattern="\d*"
          placeholder="Security Code"
          class="tw-py-5 tw-px-6 tw-placeholder-blue-50 tw-ml-2 tw-border tw-border-blue-50 tw-w-6/12"
          data-testid="cardCvv"
          autocomplete="cc-csc"
          bind:this={VALIDATION_MAPPERS.cardCvv.elem}
          use:imask={securityCodeMask}
          on:input={() => checkModificationAfterFormCompleted()}
          on:accept={(arg) => handleMaskedFieldInput(VALIDATION_MAPPERS.cardCvv.elem, arg)}
          on:complete={() => handleFieldComplete(false)}
        />
      </div>
    </div>
  {/if}
</div>

<button
  class={clsx(
    'tw-border tw-border-blue-50  tw-px-4 tw-py-3 tw-rounded-full	tw-text-base sm:tw-text-lg tw-font-bold tw-bg-yellow-50 tw-text-blue-50 tw-w-full tw-mt-5',
    !advanceButtonEnabled && 'tw-cursor-not-allowed tw-opacity-50'
  )}
  on:click={placeOrderFunc}
  disabled={!advanceButtonEnabled}
  data-testid="submit-creditCard"
>
  Place order
</button>

<!--  on:click={advanceCheckout}-->
<style lang="scss">
  .disabledForm {
    background-color: #d0dae380;
    input {
      background-color: #d0dae380;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
</style>
