const DEFAULT_QUANTITY_ATTRIB = 'data-cgn-cart-quantity';

const QUANTITY_CHANGED_EVENT_NAME = 'CGNQuantityChanged';

/*
 * Send the CGNQuantityChanged event to the document.
 * This will inform the document when the displayable quantity of the cart
 * has changed.
 */
const sendQuantityChangedEvent = (quantity) => {
  const event = new CustomEvent(QUANTITY_CHANGED_EVENT_NAME, {
    detail: {
      quantity: quantity || 0,
    },
  });
  document.dispatchEvent(event);
};

/*
 * Refresh the cart item quantity for items on the page.
 * Default: If the count is 0 refresh with a blank string.
 */
const refreshCartQuantityDisplay = (count) => {
  const quantityElems = document.querySelectorAll(
    `[${DEFAULT_QUANTITY_ATTRIB}]`
  );
  count = count || '';
  quantityElems.forEach((elem) => (elem.textContent = count));
  sendQuantityChangedEvent(count);
};

export default refreshCartQuantityDisplay;
