<script>
  // TODO: fix this module to use the validation form and advanceCheckout
  // functions that control the checkout flow
  import { onMount, onDestroy } from 'svelte';
  import squarePaymentFormParams from '@Lib/squarePaymentFormParams';
  import Spinner from '../../../components/Spinner/Spinner.svelte';
  import CreditCardDisplay from './CreditCardDisplay.svelte';
  import CreditCard from '@Models/CreditCard';
  import creditCardTokenStore from '@Lib/creditCardTokenStore';
  import ErrorDisplay from '../ErrorDisplay.svelte';

  const squarePaymentVarName = 'CGN_sqPaymentForm';
  // const { advanceCheckout } = checkoutContext()

  let existingNonce = creditCardTokenStore.token;
  let errors;
  let showSpinner = false;
  let showCardForm;
  let cardFormClass;

  // TODO: in the event that you have entered the card once already, we
  // need to find a good way to make it so that the card you chose is
  // displayed (last 4 digits) with an option to change your credit card if you
  // want to
  const getCardNonce = () => {
    showSpinner = true;
    window[squarePaymentVarName].requestCardNonce();
  };

  const displaySquareErrors = (nonceErrors) => {
    if (nonceErrors.length == 0) {
      return;
    }
    errors = nonceErrors.map((sqError) => {
      return { mesg: sqError.message };
    });
  };

  // TODO: when this fixed, use context instead to switch panels
  const advanceToConfirmation = () => {
    // advanceCheckout('creditCard')
  };

  const setNonce = (nonce) => {
    creditCardTokenStore.token = nonce;
    // advanceToConfirmation()
  };

  const setCardData = (cardData) => {
    const creditCard = new CreditCard();
    creditCard.brand = cardData.card_brand;
    creditCard.last4 = cardData.last_4;
    creditCard.save(true);
  };

  // A custom event is dispatched by the Square callbacks and is handled here
  // when a nonce was attempted to be created
  const nonceCreationHandler = (e) => {
    showSpinner = false;
    const nonceErrors = e.detail.errors;
    if (nonceErrors) {
      displaySquareErrors(nonceErrors);
      return;
    }

    const nonce = e.detail.nonce;
    if (nonce) {
      setNonce(nonce);
      setCardData(e.detail.cardData);
      advanceToConfirmation();
    }
  };

  const changeCard = () => {
    cardFormClass = '';
  };

  onMount(() => {
    document.addEventListener('nonceCreation', nonceCreationHandler);
    window[squarePaymentVarName] = new window.SqPaymentForm(
      squarePaymentFormParams(squarePaymentVarName)
    );
    window[squarePaymentVarName].build();
  });

  onDestroy(() => {
    document.removeEventListener('nonceCreates', nonceCreationHandler);
    window[squarePaymentVarName].destroy();
  });

  $: {
    showCardForm = !existingNonce;
    cardFormClass = showCardForm ? '' : 'invisible';
  }
</script>

<Spinner {showSpinner} />
<ErrorDisplay {errors} />
{#if existingNonce}
  <CreditCardDisplay />
  <span class="clickable" on:click|preventDefault={changeCard}>
    change credit card
  </span>
  <div on:click|preventDefault={advanceToConfirmation} class="cgn-btn">
    Continue with this Card &raquo;
  </div>
  {#if !cardFormClass}
    <hr />
  {/if}
{/if}
<div class="cgn-credit-card-form {cardFormClass}">
  <label for="ccNumber">Card Number</label>
  <input id="ccNumber" type="text" class="cc-input placeholder-input" />
  <label for="ccCode">CVV</label>
  <input id="ccCode" type="text" class="cc-input placeholder-input" size="3" />
  <label for="ccExpiration">Expiration</label>
  <input
    id="ccExpiration"
    type="text"
    class="cc-input placeholder-input"
    size="5"
  />
  <label for="ccPostalCode">Postal Code</label>
  <input id="ccPostalCode" type="text" class="cc-input placeholder-input" />
  <div
    data-testid="submit-creditCard"
    on:click|preventDefault={getCardNonce}
    class="cgn-checkout-next cgn-btn"
  >
    next &raquo;
  </div>
</div>

<style lang="scss">
  div.spinner-area {
    position: absolute;
    margin: 0 auto;
    opacity: 0.5;
    text-align: center;
    width: 100%;
  }

  input.placeholder-input {
    color: #fff;
    background: #fff;
    border: none;
  }

  .invisible {
    display: none;
  }

  .clickable {
    color: #3498db;
    cursor: pointer;
  }
</style>
