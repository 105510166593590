<script>
  import { onMount } from 'svelte';
  import { cart, customer } from '@/store';
  import CustomerInfoScraper from '@Lib/CustomerInfoScraper';
  import ErrorDisplay from './ErrorDisplay.svelte';
  import CartItemDisplay from '../cart/CartItemDisplay.svelte';
  import CartFooter from '../cart/CartFooter.svelte';
  import CheckoutHeader from './common/CheckoutHeader.svelte';

  let errors;

  onMount(() => {
    const scraper = new CustomerInfoScraper();
    $customer.conditionalLoad(scraper.info);
  });
</script>

<ErrorDisplay {errors} />
<div class="tw-flex tw-flex-col tw-flex-1 tw-bg-white">
  <CheckoutHeader />
  <div class="tw-flex tw-flex-1 tw-flex-col sm:tw-flex-row">
    <div
      class="tw-px-8 sm:tw-px-8 tw-py-8 tw-border-t tw-border-r tw-border-blue-50 sm:tw-w-6/12"
    >
      <slot />
    </div>
    <div
      class="tw-px-8 sm:tw-px-8 tw-pb-4 sm:tw-pb-8 tw-py-8 tw-border-t tw-border-blue-50 sm:tw-w-6/12 tw-mb-14 sm:tw-mb-0"
    >
      <h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">
        Order Summary
      </h3>
      {#each $cart.items as item (item.uniqueId)}
        <CartItemDisplay {item} readOnly />
      {/each}
      <div class="tw-border-t tw-border-blue-50 tw-pt-4">
        <CartFooter />
      </div>
    </div>
  </div>
</div>
