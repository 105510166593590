<script>
  import { tick } from 'svelte';
  import clsx from 'clsx';
  import { moneyInCents } from '@Lib/utils';
  import { cart } from '../../store';

  export let item;
  export let readOnly = false;
  let editing = false;
  $: showEditButton = editing;
  $: shouldInputBeDisabled = readOnly || (!readOnly && !showEditButton);

  let inputRef;

  const handleEdit = () => {
    editing = true;
  };

  const focusInput = async () => {
    await tick();
    inputRef.focus();
  };

  $: if (!shouldInputBeDisabled && inputRef) {
    focusInput();
  }

  /*
   *  Save the price of the item. Clear the PriceChangeRequested if it exists.
   */
  const savePrice = (event) => {
    const priceInput = event.target.value.replace(/[a-zA-Z]+/g, '');
    item.basePriceInCents = moneyInCents(priceInput);
    event.target.value = item.priceAsCurrency;
    cart.save();
    editing = false;
  };
</script>

<div class="tw-flex tw-flex-col">
  {#if item.hasAdjustablePrice}
    <input
      type="text"
      on:blur={savePrice}
      class={clsx(
        'tw-font-bold tw-text-right tw-overflow-ellipsis cgn-item-price-field',
        shouldInputBeDisabled && 'tw-bg-transparent',
        !shouldInputBeDisabled && 'tw-border tw-border-blue-50 tw-p-1'
      )}
      value={item.priceAsCurrency}
      disabled={shouldInputBeDisabled}
      bind:this={inputRef}
    />

    {#if !showEditButton && !readOnly}
      <button
        class="tw-ml-auto tw-self-baseline tw-underline"
        on:click={handleEdit}
      >
        Edit
      </button>
    {/if}
  {:else}
    <b>{item.subtotalAsCurrency}</b>
  {/if}
</div>

<style>
  .cgn-item-price-field {
    max-width: 70px;
  }
</style>
