<script>
  import { createEventDispatcher } from 'svelte';
  import clsx from 'clsx';
  export let id = '';
  export let inputMode = 'text'
  export let value = '';
  export let borderless = false;
  export let onFocus = () => {};
  export let onBlur = () => {};
  export let fullWidth = false;
  export let dataref = $$props['data-ref'];
  export let datatestid = $$props['data-testid'];
  export let placeholder = '';
  export let autocomplete = 'off';
  export let ariaLabel = placeholder;
  let showLabel = false;
  let clazz = '';
  export { clazz as class };

  const dispatch = createEventDispatcher();

  function onChange(e) {
    dispatch('change', {
      value: e.target.value,
    });
  }

  $: { showLabel = (value ? true : false) }
</script>

<div class="tw-relative">
  <input
    {id}
    class={clsx(
      'tw-py-5 tw-px-6 tw-placeholder-black',
      clazz,
      !borderless && 'tw-border tw-border-blue-50',
      fullWidth && 'tw-w-full'
    )}
    bind:value
    on:focus={onFocus}
    on:blur={onBlur}
    on:change={onChange}
    data-ref={dataref}
    data-testid={datatestid}
    aria-label={ariaLabel}
    autocomplete={autocomplete}
    placeholder={placeholder}
    inputmode={inputMode}
  />
  {#if showLabel}
    <label for="{id}" class="top-line tw-text-sm">{placeholder}</label>
  {/if}
</div>

<style lang="scss">
  label.top-line {
    position: absolute;
    z-index: 1;
    bottom: 3.6em;
    left: 8px;
    padding: 0px 5px;
    background: white;
  }
</style>
