<script>
  import { fullStateNameFor } from '@Lib/usStates';

  export let order;

  let address = order.shippingAddress;
  const cityInfo = () => {
    const state = fullStateNameFor(address.state);
    const cityState = [address.city, state].join(' ');
    return [cityState, address.zipCode].join(', ');
  };
</script>

<div class="cgn-order-display tw-px-8 sm:tw-px-8 sm:tw-py-8 sm:tw-w-6/12">
  <h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">
    Your Contact Info
  </h3>
  <div class="tw-flex tw-p-3 infoBox">
    <div class="tw-flex tw-flex-col">
      <span> {order.customer.email} </span>
    </div>
  </div>
  <h3 class="tw-text-blue-50 tw-text-xl tw-py-3 tw-font-bold">
    Shipping Address
  </h3>
  <div class="tw-flex tw-p-3 infoBox">
    <div class="tw-flex tw-flex-col">
      <span> {address.associatedName} </span>
      <span>
        {address.address}
        {#if address.address2}{address.address2}{/if}
      </span>
      <span>{cityInfo()}</span>
    </div>
  </div>
  <h3 class="tw-text-blue-50 tw-text-xl tw-py-3 tw-font-bold">
    Payment Method
  </h3>
  <div class="tw-flex tw-p-3 infoBox">
    {order.creditCard.displayableInfo}
  </div>
</div>
