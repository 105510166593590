class Panel {
  constructor({ name, key, component, resetCheckout, blankPage }) {
    this.name = name;
    this.key = key;
    this.component = component;
    this._resetCheckout = resetCheckout;
    this.blankPage = blankPage;
    this._completed = false;
  }

  get completed() {
    return this._completed;
  }

  set completed(val) {
    this._completed = val;
  }

  get incomplete() {
    return !this.completed;
  }

  get resetCheckout() {
    return !!this._resetCheckout;
  }
}

export default Panel;
