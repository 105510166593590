<script>
  import AppConfig from '@Lib/AppConfig';
  import { closeDisplay } from '@Lib/panelManager';
  import { getAssetURL } from '@Lib/utils/url';

  const checkoutImageUrl = AppConfig().checkoutImageUrl;
</script>

<div class="tw-flex tw-p-4 tw-items-center">
  {#if checkoutImageUrl}
    <img class="tw-w-32" src={checkoutImageUrl} alt="cart-logo" />
  {/if}
  <img
    src={`${getAssetURL('close-icon.svg')}`}
    class="tw-ml-auto tw-bg-yellow-50 tw-rounded-full tw-px-3 tw-py-2 tw-cursor-pointer"
    data-testid="close-cart"
    alt="close"
    title="close"
    aria-label="close"
    on:click={closeDisplay}
  />
</div>
