<script>
  import AppConfig from '@Lib/AppConfig';
  import CartItemDisplay from './CartItemDisplay.svelte';
  import CartHeader from './CartHeader.svelte';
  import clsx from 'clsx';
  import { isMobile } from '@/lib/utils/screen';
  export let cart;
  let cartItemDisplayRefs = [];

  $: {
    const mostRecentlyUpdatedNode =
      cartItemDisplayRefs[cart.mostRecentlyAddedItemIndex];
    mostRecentlyUpdatedNode?.focus();
  }
</script>

<CartHeader {cart} />
<div
  class={clsx(
    'tw-flex tw-flex-col tw-py-6 tw-px-8 sm:tw-px-10',
    (cart.isEmpty || isMobile()) && 'tw-flex-1'
  )}
>
  {#if cart.isEmpty}
    <div class="tw-text-5xl tw-text-blue-50 tw-font-bold tw-py-4">
      <p>Hi,</p>
      <p role="alert">
        Your {AppConfig().cartName} is empty.
      </p>
    </div>
  {:else}
    {#each cart.items as item, i}
      <CartItemDisplay {item} bind:this={cartItemDisplayRefs[i]} />
    {/each}
  {/if}
</div>
