import VariantSelection from '@Models/VariantSelection';
import ValueFinder from '@Lib/ValueFinder';

class VariantSelectionBuilder {
  /*
  @param (element) elem - the variant [grouping] name for the variant selection
  */
  constructor(elem) {
    this.elem = elem;
    this._selectedOptionElem = undefined;
  }

  get variantId() {
    return this.elem.dataset.cgnValue;
  }

  get isSelect() {
    return this.elem.nodeName === 'SELECT';
  }

  get optionId() {
    if (!this.selectedOptionElem) return undefined;

    const valFinder = new ValueFinder(this.selectedOptionElem, 'option');
    return valFinder.findValue();
  }

  // Get the Selected Option or checked RadioButton elem; memoize it.
  get selectedOptionElem() {
    if (!this._selectedOptionElem) {
      if (this.isSelect) {
        this._selectedOptionElem = this.elem.options[this.elem.selectedIndex];
      } else {
        this._selectedOptionElem = this.elem.querySelector('input:checked');
      }
    }
    return this._selectedOptionElem;
  }

  get optionName() {
    if (!this.selectedOptionElem) return undefined;

    const finder = new ValueFinder(this.selectedOptionElem, 'label');
    return finder.findValue();
  }

  /*
  Will return, either a variantName element, OR will return the variant element
  itself and attempt to discern the variant name from that.
  */
  get variantNameElem() {
    return (
      this.elem.querySelector("[data-cgn-attr='variantName']") || this.elem
    );
  }

  get variantName() {
    const finder = new ValueFinder(this.variantNameElem, 'variantName');
    return finder.findValue();
  }

  // returns a price in cents, expressed as an integer
  get priceModifierInCents() {
    const finder = new ValueFinder(this.selectedOptionElem, 'priceModifier');
    const modifier = finder.findValue();
    if (!modifier) return 0;

    return Number.parseInt(modifier);
  }

  get noSelectionMade() {
    return !this.optionId;
  }

  get variantSelection() {
    if (this.noSelectionMade) return null;

    return new VariantSelection({
      variantName: this.variantName,
      name: this.optionName,
      priceInCents: this.priceModifierInCents,
      variantId: this.variantId,
      optionId: this.optionId,
    });
  }
}

export default VariantSelectionBuilder;
