import { BasicValidation } from '@Lib/basicValidators';
import { simpleSerialization } from '@Lib/utils';

class Customer extends BasicValidation {
  constructor(attribs = {}) {
    super();
    this.email = attribs.email;
    this.phone = attribs.phone;
    this.errors = [];
  }

  static serialize(customer) {
    return simpleSerialization(customer, ['email', 'phone']);
  }

  static deserialize(attribs = {}) {
    return new Customer(attribs);
  }

  validateAll() {
    this.clearErrors();
    this.validatePresenceOf('email');
    this.validatePhoneNumber('phone');
    return this.errors.length == 0;
  }

  isValid() {
    this.validateAll();
    return this.errors.length === 0;
  }

  // Set props on any property that is null, and not set properties
  // that have already been set. This prioritized the existing props.
  conditionalLoad(props = {}) {
    for (const [key, value] of Object.entries(props)) {
      if (!this[key]) {
        this[key] = value;
      }
    }
  }
}

export default Customer;
