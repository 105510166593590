import CartItem from '@Models/CartItem';
import { moneyInCents } from './utils';
import VariantSelectionBuilder from '@Lib/VariantSelectionBuilder';
import ValueFinder from '@Lib/ValueFinder';

const MERGEABLE_ATTRIBUTE = 'data-cgn-mergeable';

/*
Take in a product element. Return a CartItem.
A Product Element is an element on the page that has a `data-cgn-attr="id"`
attribute.
*/
const makeCartItemFromProductElem = (prodElem) => {
  const scraper = new ProductDataScraper(prodElem);
  return scraper.cartItem;
};

/*
Return a single cart item for the root element's product Id supplied.

Will look for all product data structures that use that
1. have the same product id
2. and have the mergeable attribute

It will then scrape and merge them into a single cart item.
*/
const mergedCartItemFromRootElem = (prodElem) => {
  // If product elem that was clicked is marked as 'mergeable.
  if (prodElem.hasAttribute(MERGEABLE_ATTRIBUTE)) {
    const productId = prodElem.dataset.cgnValue;
    const productElems = Array.from(
      document.querySelectorAll(
        `[data-cgn-attr="id"][data-cgn-value="${productId}"][${MERGEABLE_ATTRIBUTE}]`
      )
    );
    const items = productElems.map(makeCartItemFromProductElem);
    return CartItem.mergeItems(items);
  }

  // Else we simply make a cart item from the product element.
  return makeCartItemFromProductElem(prodElem);
};

/* Scrape all products in a group.
@param (elem) rootElem: the html element of the product group
@return [CartItem]
*/
const scrapeGroupItems = (rootElem) => {
  const productElems = Array.from(
    rootElem.querySelectorAll('[data-cgn-attr="id"]')
  );
  return productElems.map((elem) => mergedCartItemFromRootElem(elem));
};

/*
Take a clicked element (a button or link), find it's root element (either
a product group or a product), and then parse all the items.
@param (elem) clickedElem: the element that was clicked.
@return [CartItem]
 */
const scrapeProductAndAddons = (clickedElem) => {
  const rootElem = clickedElem.closest(
    '[data-cgn-attr="group"], [data-cgn-attr="id"]'
  );
  if (rootElem.dataset.cgnAttr === 'group') {
    return scrapeGroupItems(rootElem);
  }

  // If not a product group, scrape the item as a normal product element
  return [mergedCartItemFromRootElem(rootElem)];
};

// Scrape the product data from the current webpage.
class ProductDataScraper {
  /*
  @param (element) rootElem: the root Product element to search from
  */
  constructor(rootElem) {
    this.rootElem = rootElem;
    this.hasAdjustablePrice = false;
  }

  elemByKeyName(keyName, all = false) {
    const selector = `[data-cgn-attr='${keyName}']`;
    if (all) {
      return this.rootElem.querySelectorAll(selector);
    }
    return this.rootElem.querySelector(selector);
  }

  // The keyName is the base key name of the element.
  valueByKeyName(keyName) {
    const elem = this.elemByKeyName(keyName);
    if (!elem) return '';

    const finder = new ValueFinder(elem, keyName);
    return finder.findValue();
  }

  get productId() {
    const finder = new ValueFinder(this.rootElem, 'id');
    return finder.findValue();
  }

  get productName() {
    return this.valueByKeyName('name');
  }

  get productDescription() {
    return this.valueByKeyName('description');
  }

  // TODO: make this to take multiple images, or possibly better yet,
  // make a new data attribute for `image-urls` (plural) that can take in
  // multiple images
  get imageUrl() {
    return this.valueByKeyName('image');
  }

  get basePriceVal() {
    const priceElem = this.elemByKeyName('price');
    if (!priceElem) return 0;

    const priceVal = this.valueByKeyName('price');
    if (priceElem.dataset.cgnAdjustable) this.hasAdjustablePrice = true;
    return priceVal;
  }

  get basePriceInCents() {
    return moneyInCents(this.basePriceVal);
  }

  get quantity() {
    return this.valueByKeyName('quantity');
  }

  get variantElems() {
    return this.elemByKeyName('variant', true);
  }

  get variantSelections() {
    return Array.from(this.variantElems)
      .map((elem) => {
        return new VariantSelectionBuilder(elem).variantSelection;
      })
      .filter(Boolean);
  }

  /*
  If set in the root element, make this element dependent of another element
  in the cart.
   */
  get dependentOf() {
    return this.rootElem.dataset.cgnDependentOf;
  }

  get additionalCheckoutHTML() {
    return this.valueByKeyName('additionalCheckoutHTML');
  }

  get cartItem() {
    return new CartItem({
      productId: this.productId,
      name: this.productName,
      basePriceInCents: this.basePriceInCents,
      description: this.productDescription,
      imageUrl: this.imageUrl,
      variantSelections: this.variantSelections,
      quantity: this.quantity,
      hasAdjustablePrice: this.hasAdjustablePrice,
      dependentOf: this.dependentOf,
      additionalCheckoutHTML: this.additionalCheckoutHTML
    });
  }
}

export { scrapeProductAndAddons };
