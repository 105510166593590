<script>
  import { onMount } from "svelte";
  import AppConfig from "@Lib/AppConfig";
  import Checkout from '@Pages/checkout/Checkout.svelte';
  import CustomerInfoForm from '@Pages/checkout/CustomerInfoForm.svelte';
  import AddressForm from "@Pages/checkout/AddressForm.svelte";
  import { cart, billingAddress, customer, shippingAddress } from "../../store";
  import CustomerInfoScraper from "@Lib/CustomerInfoScraper";
  import { PANELS_KEYS, setCompletedAndAdvance } from "@Lib/panelManager";

  let customerErrors;
  let addressErrors;

  const addressFormAdditionalHTML =
    AppConfig().checkout.addressFormAdditionalHTML || '';

  const copyToBillingAddress = () => {
    const addressCopy = $shippingAddress.asBillingAddress;
    billingAddress.set(addressCopy);
    billingAddress.save();
  };

  const advanceCheckout = () => {
    const customerValid = $customer.isValid();
    const addressValid = $shippingAddress.isValid();
    if (customerValid && addressValid) {
      customer.save();
      shippingAddress.save();
      copyToBillingAddress();

      setCompletedAndAdvance(PANELS_KEYS.CheckoutInfo);
      return;
    }
    customerErrors = $customer.errors;
    addressErrors = $shippingAddress.errors;
  };

  onMount(() => {
    const scraper = new CustomerInfoScraper();
    $customer.conditionalLoad(scraper.info);
  });

</script>

<Checkout>
  <CustomerInfoForm customer="{$customer}" errors={customerErrors} />
  <AddressForm address={$shippingAddress} errors={addressErrors} />

  {@html $cart.additionalCheckoutHTML() }
  <div class="tw-mt-3">
    {@html addressFormAdditionalHTML}
  </div>

  <div class="cgn-cart-actions tw-text-center tw-mt-5">
    <button
      class="tw-border tw-border-blue-50  tw-px-4 tw-py-3 tw-rounded-full	tw-text-base sm:tw-text-lg tw-font-bold tw-bg-yellow-50 tw-text-blue-50 tw-w-full"
      data-testid="submit-shippingAddress"
      on:click={advanceCheckout}
    >
      Continue to Payment
    </button>
  </div>
<!--
 Put advance button here.
 This should validate and error-display on both forms. And then return a true/false
 as to whether to advance checkout or not.
-->
</Checkout>
