import AppConfig from './AppConfig';
import Panel from './Panel';
import { clearAllStores, currentPanelKey } from 'src/store';
import { get } from 'svelte/store';
export var PANELS_KEYS;
(function (PANELS_KEYS) {
    PANELS_KEYS["Cart"] = "cart";
    PANELS_KEYS["CheckoutInfo"] = "checkoutInfo";
    PANELS_KEYS["CheckoutPayment"] = "payment";
    PANELS_KEYS["ThankYou"] = "thankYou";
})(PANELS_KEYS || (PANELS_KEYS = {}));
const PANEL_CONFIGS = [
    {
        name: 'Items',
        key: PANELS_KEYS.Cart,
        component: 'CartPanel',
        blankPage: true,
    },
    {
        name: 'Checkout',
        key: PANELS_KEYS.CheckoutInfo,
        component: 'CheckoutInfoAndAddress',
    },
    {
        name: 'Credit Card',
        key: PANELS_KEYS.CheckoutPayment,
        component: 'CreditCardManager',
    },
    {
        name: 'Thank you for your Order!',
        key: PANELS_KEYS.ThankYou,
        component: 'ThankYou',
        blankPage: true,
        resetCheckout: true,
    },
];
export const PANELS_STYLE_CONFIG = {
    [PANELS_KEYS.CheckoutInfo]: {
        width: '100%',
        height: '100vh',
        backgroundColor: 'tw-bg-white',
    },
    [PANELS_KEYS.CheckoutPayment]: {
        width: '100%',
        backgroundColor: 'tw-bg-white',
        height: '100vh',
    },
    [PANELS_KEYS.ThankYou]: {
        width: '100%',
        backgroundColor: 'tw-bg-white',
        height: '100vh',
    },
    [PANELS_KEYS.Cart]: {
        width: '540px',
        backgroundColor: 'tw-bg-yellow-50',
        height: '100vh',
    },
};
const currentPanelConfigs = () => {
    if (!AppConfig().forPickup())
        return PANEL_CONFIGS;
    return PANEL_CONFIGS.filter((config) => config.key != PANELS_KEYS.CheckoutInfo);
};
const PANELS = currentPanelConfigs().map((config) => new Panel(config));
const _nextIncompletePanel = () => {
    return PANELS.find((panel) => panel.incomplete);
};
// Finds the next incomplete panel, and sets the currentPanelKey to its key
const _advance = () => {
    const nextPanel = _nextIncompletePanel();
    gotoPanel(nextPanel.key);
};
// Sets multiple panels complete, given their keys
const _setCompleted = (...keys) => {
    keys.forEach((key) => {
        const panel = getPanel(key);
        panel.completed = true;
    });
};
const _setAllPanelsToIncomplete = () => {
    PANELS.forEach((panel) => {
        panel.completed = false;
    });
};
// Clear all stores and their storge
const _resetCheckout = () => {
    clearAllStores();
    _setAllPanelsToIncomplete();
};
const _indexForPanel = (key) => {
    return PANELS.findIndex((panel) => panel.key === key);
};
const _getCurrentPanel = () => {
    const panelKey = get(currentPanelKey);
    return getPanel(panelKey);
};
const _currentPanelIndex = () => {
    const currentPanel = _getCurrentPanel();
    return _indexForPanel(currentPanel.key);
};
const _previousPanelKey = () => {
    const previousPanel = PANELS[_currentPanelIndex() - 1];
    return previousPanel.key;
};
const getPanel = (key) => {
    return PANELS.find((panel) => panel.key === key);
};
// Returns all panels up to the given panel that has the key
const panelsUpTo = (key) => {
    const index = _indexForPanel(key);
    return PANELS.slice(0, index);
};
const gotoPanel = (key) => {
    currentPanelKey.set(key);
    const panel = getPanel(key);
    if (panel.resetCheckout)
        _resetCheckout();
};
const setCompletedAndAdvance = (...keys) => {
    _setCompleted(...keys);
    _advance();
};
const goBack = () => {
    const key = _previousPanelKey();
    gotoPanel(key);
};
const closeDisplay = () => {
    currentPanelKey.set(null);
};
export { getPanel, gotoPanel, goBack, setCompletedAndAdvance, panelsUpTo, closeDisplay, };
