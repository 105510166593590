import refreshCartQuantityDisplay from '@Lib/refreshCartQuantityDisplay';

const CGN_CART_KEY = 'CGN_CART';
const CGN_CHECKOUT_KEY = 'CGN_CHECKOUT';
const CGN_PLACED_ORDER_KEY = 'CGN_PLACED_ORDER';

// The key determines the type of storage as well as it's storage key.
const KEY_STORAGE_MAPPINGS = {
  cart: { storage: window.localStorage, storageKey: CGN_CART_KEY },
  customer: { storage: window.sessionStorage, storageKey: CGN_CHECKOUT_KEY },
  shippingAddress: {
    storage: window.sessionStorage,
    storageKey: CGN_CHECKOUT_KEY,
  },
  billingAddress: {
    storage: window.sessionStorage,
    storageKey: CGN_CHECKOUT_KEY,
  },
  creditCard: { storage: window.sessionStorage, storageKey: CGN_CHECKOUT_KEY },
  payment: { storage: window.sessionStorage, storageKey: CGN_CHECKOUT_KEY },
  fees: { storage: window.sessionStorage, storageKey: CGN_CHECKOUT_KEY },
};

const getDataForStorageMapping = (storage, storageKey) => {
  const jsonData = storage.getItem(storageKey) || '{}';
  return JSON.parse(jsonData);
};

// Used for any extraneous calls to the local storage
class StorageWrangler {
  getAttribsByKey(key) {
    const { storage, storageKey } = KEY_STORAGE_MAPPINGS[key];
    const allAttribs = getDataForStorageMapping(storage, storageKey);
    return allAttribs[key];
  }

  setAttribsOnKey(key, attribs = {}) {
    const { storage, storageKey } = KEY_STORAGE_MAPPINGS[key];
    const allAttribs = getDataForStorageMapping(storage, storageKey) || {};
    const writeAttribs = { [key]: attribs };
    const writeData = { ...allAttribs, ...writeAttribs };
    storage.setItem(storageKey, JSON.stringify(writeData));
  }

  get cartObj() {
    const obj = window.localStorage.getItem(CGN_CART_KEY) || '{}';
    return JSON.parse(obj);
  }

  get checkoutObj() {
    const obj = window.sessionStorage.getItem(CGN_CHECKOUT_KEY) || '{}';
    return JSON.parse(obj);
  }

  get placedOrderObj() {
    const placedOrderJson = window.sessionStorage.getItem(CGN_PLACED_ORDER_KEY);
    return JSON.parse(placedOrderJson);
  }

  clearCart() {
    window.localStorage.removeItem(CGN_CART_KEY);
    refreshCartQuantityDisplay(0);
  }

  clearCheckout() {
    window.sessionStorage.removeItem(CGN_CHECKOUT_KEY);
  }

  clearAll() {
    this.clearCart();
    this.clearCheckout();
  }

  // combines the checkoutObj and the cartObj and saves it in the
  // CGN_PLACE_ORDER session key
  savePlacedOrder(additionalProps = {}) {
    const placedOrderObj = {
      ...this.checkoutObj,
      ...this.cartObj,
      ...additionalProps,
    };
    window.sessionStorage.setItem(
      CGN_PLACED_ORDER_KEY,
      JSON.stringify(placedOrderObj)
    );
  }
}

export const storage = new StorageWrangler();
