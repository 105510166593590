<script>
  import { creditCard, payment } from 'src/store';
  import { creditCardContext } from '@Lib/componentContexts';
  import {
    goBack,
    setCompletedAndAdvance,
    PANELS_KEYS,
  } from '@Lib/panelManager';

  const { toggleEdit } = creditCardContext();

  const advanceCheckout = () => {
    if ($payment.isValid()) {
      payment.save();
      setCompletedAndAdvance(PANELS_KEYS.CheckoutPayment);
    }

    toggleEdit();
  };
</script>

<h5>
  Paying By:
  {$creditCard.displayableInfo}
  <button data-testid="editCreditCard" onClick={toggleEdit}>Change Card</button>
</h5>

<div class="button-container">
  <button onClick={goBack} data-testid="previousStep" variant="raised">
    &laquo; Back
  </button>
  <button
    data-testid={`submit-creditCardDisplay`}
    onClick={advanceCheckout}
    variant="raised"
  >
    Next &raquo;
  </button>
</div>

<style lang="scss">
  .button-container {
    padding-top: 16px;
    display: flex;
    justify-content: space-between;
  }
  :global(.mdc-menu-surface--open) {
    z-index: 10;
  }
</style>
