// To be mixed in with the basicValidators. Not to be used on its own.

import { creditCardBrandFromNumber } from './creditCardNumberParser';

export default {
  validateCreditCardNumber(prop) {
    if (creditCardBrandFromNumber(this[prop])) {
      return true;
    }
    this.addError({ name: prop, mesg: 'does not match any known credit card' });
  },
};
