/*
This Regex will parse any backgroundImage style.
example:
```css
url('http://example.com/image.png')
```
Used to only match the inner url.
*/
const BACKGROUND_URL_REGEX = /url\(["']?([^"']*)["']?\)/;

/*
Find a URL from an element. If the element has a `src` attribute, then that is
returned, otherwise, we will look for a background image or a data attribute.
If a url is a relative URL, we prefix it with the window.location.origin.
*/
class ImageUrlFinder {
  constructor(elem) {
    this.elem = elem;
  }

  get urlBaseLocation() {
    return window.location.origin;
  }

  get originalSrc() {
    return this.elem.src;
  }

  // Returns the computed style for the element.
  get elemStyle() {
    return window.getComputedStyle(this.elem, false);
  }

  get backgroundImageUrl() {
    const url = this.elemStyle.backgroundImage;
    if (!url) return null;

    return url.match(BACKGROUND_URL_REGEX)?.[1];
  }

  // Return the data cgn value for this image element.
  get dataAttribUrl() {
    return this.elem.dataset.cgnValue;
  }

  hasHost(url) {
    return !!url.match(/^http/);
  }

  ensureHostForUrl(url) {
    if (this.hasHost(url)) return url;

    // Remove the initial '/' if it exists.
    url = url.replace(/^\/*/, '');
    return `${this.urlBaseLocation}/${url}`;
  }

  derivedSrc() {
    const url = this.backgroundImageUrl || this.dataAttribUrl;
    if (!url) return;

    return this.ensureHostForUrl(url);
  }

  imageUrl() {
    return this.originalSrc || this.derivedSrc();
  }
}

const findImageUrl = (elem) => {
  const finder = new ImageUrlFinder(elem);
  return finder.imageUrl();
};

export { findImageUrl };
