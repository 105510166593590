import CartiganServiceBase from '@Services/CartiganServiceBase';
import { fourDigitYear } from '@Lib/utils';
import Payment from '@Models/Payment';

const creditCardParams = (creditCard) => {
  const {
    number,
    verificationCode,
    expirationMonth,
    expirationYear,
    billingName,
    postalCode,
  } = creditCard;
  return {
    number,
    cvc: verificationCode,
    exp_month: expirationMonth,
    exp_year: fourDigitYear(expirationYear),
    billing_name: billingName,
    postal_code: postalCode,
  };
};

class CreditCardTokenService extends CartiganServiceBase {
  async getToken(creditCard) {
    const response = await this.makeRequest({
      url: 'cart/tokens',
      body: creditCardParams(creditCard),
    });

    response.payment = new Payment({
      creditCard,
      token: response.body.token,
      billingName: creditCard.billingName,
      postalCode: creditCard.postalCode,
    });
    return response;
  }
}

export default CreditCardTokenService;
