/**
 * Camelize a string, cutting the string by multiple separators like
 * hyphens, underscores and spaces.
 *
 * @param {text} string Text to camelize
 * @return string Camelized text
 */
const camelize = (text) => {
  return text.replace(/^([A-Z])|[\s-_]+(\w)/g, (match, p1, p2) => {
    if (p2) return p2.toUpperCase();
    return p1.toLowerCase();
  });
};

const obfuscate = (str, visibleChars = 4, obfuscationChar = '*') => {
  str = str.toString();
  const fillLength = str.length - visibleChars;
  const fill = new Array(fillLength + 1).join(obfuscationChar);
  return fill + str.slice(-visibleChars);
};

const twoDigitYear = (date = null) => {
  date = date || new Date();
  return date.getFullYear().toString().substr(-2);
};

const fourDigitYear = (yearSuffix) => {
  if (yearSuffix.length === 4) {
    return yearSuffix;
  }
  yearSuffix = yearSuffix.substr(-2, 2);
  const date = new Date();
  const yearPrefix = date.getFullYear().toString().substr(0, 2);
  return `${yearPrefix}${yearSuffix}`;
};

const toSentence = (val) => {
  if (!val) {
    return '';
  }

  const newText = val
    .replace(/([A-Z])/g, ' $1')
    .replace(/_/g, ' ')
    .trim();
  return newText.charAt(0).toUpperCase() + newText.slice(1);
};

const stripSpacesAndDashes = (val) => {
  if (!val) {
    return '';
  }

  const newText = val.replace(/[ -]/g, '').trim();
  return newText;
};

const addEventListeners = ({ selector, event, fn }) => {
  const elems = document.querySelectorAll(selector);
  elems.forEach((el) => el.addEventListener(event, fn));
};

const removeEventListeners = ({ selector, event, fn }) => {
  const elems = document.querySelectorAll(selector);
  elems.forEach((el) => el.removeEventListener(event, fn));
};

// TODO: allow for different currencies, as well as display
const convertToCurrency = (number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};

const parseNum = (text) => {
  const numberMatch = text.match(/(\d+(\.\d{1,2})?)/);
  if (!numberMatch) return 0;
  const number = Number.parseFloat(numberMatch[0]);
  return number;
};

// Will also remove any currency markers, if any, as well as convert to a
// non-negative number.
const moneyInCents = (number) => {
  number = Math.max(0, parseNum(number.toString()));
  return Math.round(number * 100);
};

const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) return false;

  for (let key of keys1) {
    if (object1[key] !== object2[key]) return false;
  }
  return true;
};

//Used to determine if an element is a form input
const isFormInput = (elem) =>
  /^(?:input|select|textarea|button|option)$/i.test(elem.nodeName);

/*
Used to perform a simple serialization of a Model Instance, wherein the
properties of the Model Instance match the properties of the resulting
object literal.
@param {model} modelInstance - the model Instance
@param {array} attribKeys - the property names to return
 */
const simpleSerialization = (modelInstance, attribKeys = []) => {
  return attribKeys.reduce((attribs, key) => {
    attribs[key] = modelInstance[key];
    return attribs;
  }, {});
};

/*
@param (class) klass: the class of the model
@param (array or object) attribs: the attribs, or array of attribs for the new
instance of the model
*/
const simpleDeserialization = (klass, attribs) => {
  if (Array.isArray(attribs)) {
    return attribs.map((instanceAttribs) => new klass(instanceAttribs));
  }

  // If not an array, then simply make a new instance of the class.
  return new klass(attribs);
};

/*
Takes in object literals. Returns the objects, where all truthy values
are merged, overwriting previous truthy values, if present.
Falsey values are excluded.
*/
const objectMerger = (objects) => {
  return objects.reduce((finalObject, currentObject) => {
    for (const key in currentObject) {
      if (currentObject[key]) finalObject[key] = currentObject[key];
    }
    return finalObject;
  }, {});
};

export {
  camelize,
  obfuscate,
  twoDigitYear,
  fourDigitYear,
  toSentence,
  stripSpacesAndDashes,
  convertToCurrency,
  parseNum,
  moneyInCents,
  addEventListeners,
  removeEventListeners,
  shallowEqual,
  isFormInput,
  simpleSerialization,
  simpleDeserialization,
  objectMerger,
};
