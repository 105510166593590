import { simpleSerialization } from '@Lib/utils';
import { BasicValidation } from '@Lib/basicValidators';

class Address extends BasicValidation {
  constructor(attribs = {}) {
    super();
    this.recipientName = attribs.recipientName;
    this.billingName = attribs.billingName;
    this.address = attribs.address;
    this.address2 = attribs.address2;
    this.city = attribs.city;
    this.state = attribs.state;
    this.zipCode = attribs.zipCode;
    this.sameAsBillingAddress = attribs.sameAsBillingAddress;
    this.country = this.country || 'USA';
    this.type = attribs.type || 'shipping';
    this.errors = [];
  }

  static serialize(address) {
    const baseAttribs = simpleSerialization(address, [
      'type',
      'address',
      'address2',
      'city',
      'state',
      'zipCode',
      'country',
    ]);
    if (address.type === 'billing') {
      const billingName = address.sameAsBillingAddress
        ? address.recipientName
        : address.billingName;
      return { ...baseAttribs, billingName };
    }

    // Else assume shipping address. Serialize that way.
    return {
      ...baseAttribs,
      recipientName: address.recipientName,
      sameAsBillingAddress: address.sameAsBillingAddress,
    };
  }

  static deserialize(attribs = {}) {
    return new Address(attribs);
  }

  // Returns either the recipientName or the billingName, based on the
  // address type.
  get associatedName() {
    if (this.isShippingAddress) return this.recipientName;

    return this.billingName;
  }

  validateAll() {
    this.clearErrors();
    if (this.isShippingAddress) {
      this.validatePresenceOf('recipientName');
    } else {
      this.validatePresenceOf('billingName');
    }
    this.validatePresenceOf('address');
    this.validatePresenceOf('city');
    this.validatePresenceOf('state');
    this.validatePresenceOf('zipCode');
  }

  isValid() {
    this.validateAll();
    return this.errors.length == 0;
  }

  get isShippingAddress() {
    return this.type == 'shipping';
  }

  get isBillingAddress() {
    return this.type == 'billing';
  }

  get addressKey() {
    return `${this.type}Address`;
  }

  get cityStateZip() {
    return `${this.city} ${this.state}, ${this.zipCode}`
  }

  // Makes a copy of a shipping address, returned as a billing address, with
  // the properties set for a billing address.
  get asBillingAddress() {
    if (this.isBillingAddress) return this;

    const shippingAttribs = Address.serialize(this);

    const attribs = {
      ...shippingAttribs,
      type: 'billing',
      billingName: shippingAttribs.recipientName,
      recipientName: undefined,
      sameAsBillingAddress: undefined,
    };
    return Address.deserialize(attribs);
  }

  get shouldCopyToBillingAddress() {
    return this.isShippingAddress && this.sameAsBillingAddress;
  }
}

export default Address;
