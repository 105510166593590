import CartiganServiceBase from '@Services/CartiganServiceBase';
import orderParams from '@Lib/orderParams';

class TaxEstimateService extends CartiganServiceBase {
  async forOrder(order) {
    return await this.makeRequest({
      url: 'tax_calculations',
      body: orderParams(order),
    });
  }
}

export default TaxEstimateService;
