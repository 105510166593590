import CartiganServiceBase from '@Services/CartiganServiceBase';
import orderParams from '@/lib/orderParams';

class PlacerService extends CartiganServiceBase {
  async place(order) {
    return await this.makeRequest({
      url: 'orders',
      body: orderParams(order),
    });
  }
}

export default PlacerService;
