import { convertToCurrency } from '@Lib/utils';
import cartItemEquality from '@Lib/cartItemEquality';
import CartItem from '@Models/CartItem';
import refreshCartQuantityDisplay from '@Lib/refreshCartQuantityDisplay';

class Cart {
  constructor(attribs = {}) {
    // CartItems may be set directly through the `items` method.
    this._items = attribs.items || [];
    this._displayableQuantity = 0;
  }

  static serialize(cart) {
    return { items: cart.items.map((item) => CartItem.serialize(item)) };
  }

  static deserialize(attribs = {}) {
    attribs.items = attribs.items || [];

    const items = attribs.items.map((itemAttribs) => {
      return CartItem.deserialize(itemAttribs);
    });

    return new Cart({ items });
  }

  // Return the full CartItem objects, with adjustable-price items
  // below the main items.
  get items() {
    return this._items.sort(
      (a, b) => !!a.hasAdjustablePrice - !!b.hasAdjustablePrice
    );
  }

  // `val` is an array of CartItem objects.
  set items(val) {
    this._items = val;
  }

  /*
    Delete an item by looping/filtering/re-creating the cart list.
    Remove all items that match the uniqueId.
    Remove eligible dependent products, or zero out their price.
  */
  deleteItem(itemToDelete) {
    const multiple = this._parentProductCount(itemToDelete.productId) > 1;
    this.items = this.items.reduce((items, item) => {
      // Zero out any cart items that were attempted to be directly deleted,
      // but should be zeroed out instead.
      if (item.shouldBeZeroedOut(itemToDelete.uniqueId)) item.zeroOutPrice();

      // Construct the new array of undeleted items.
      if (
        !item.shouldBeDeleted(
          itemToDelete.uniqueId,
          itemToDelete.productId,
          multiple
        )
      ) {
        items.push(item);
      }
      return items;
    }, []);
    this.recalculateDisplayableQuantity();
  }

  changeItemQuantity(item, newQuantity) {
    newQuantity = Math.max(newQuantity, 1);
    item.quantity = newQuantity;
    this.recalculateDisplayableQuantity();
    return item.quantity;
  }

  addToCart(newItem) {
    const existingItem = this._productAlreadyInCart(newItem);
    if (existingItem) {
      this.mostRecentlyAddedItem = existingItem;
      if (existingItem.hasAdjustablePrice) {
        this._addToExistingPrice(existingItem, newItem.basePriceInCents);
      } else {
        this._addToExistingQuantity(existingItem, newItem.quantity);
      }
    } else {
      this.mostRecentlyAddedItem = newItem;
      this.items = [...this.items, newItem];
    }
    this.recalculateDisplayableQuantity();
  }

  get mostRecentlyAddedItemIndex() {
    return (
      this.mostRecentlyAddedItem &&
      this.items.findIndex((currentItem) =>
        cartItemEquality(currentItem, this.mostRecentlyAddedItem)
      )
    );
  }

  get grandTotalInCents() {
    return this.itemSubtotalInCents;
  }

  get grandTotal() {
    return this.grandTotalInCents / 100;
  }

  // Returns a currency
  get grandTotalAsCurrency() {
    return convertToCurrency(this.grandTotal);
  }

  get itemSubtotalInCents() {
    return this.items.reduce((totalCents, item) => {
      return totalCents + item.subtotalInCents;
    }, 0);
  }

  get hasItems() {
    return !!this.items.length;
  }

  get isEmpty() {
    return !this.hasItems;
  }

  get displayableQuantity() {
    return this._displayableQuantity;
  }

  /*
  Central managing function for displayable cart quantity.
  Will coordinate whether DOM update is necessary depending on whether the
  displayable cart quantity has actually changed.
  */
  recalculateDisplayableQuantity() {
    const newQuantity = this._calculatedDisplayableQuantity();
    if (this._displayableQuantity === newQuantity) return;

    this._displayableQuantity = newQuantity;
    refreshCartQuantityDisplay(this._displayableQuantity);
  }

  additionalCheckoutHTML() {
    return this.items.filter((item) => {
     return !!item.additionalCheckoutHTML;
    }).map((item) => item.additionalCheckoutHTML).join('')
  }

  /*
   * Calculate displayable quantity for the cart.
   * Default: represents the number of unique non-dependent items in the cart.
   * TODO: allow for CGN configuration that will allow for this to be calculated
   * differently.
   */
  _calculatedDisplayableQuantity() {
    return this.items.reduce(
      (acc, item) => (item.isParentItem ? acc + item.quantity : acc),
      0
    );
  }

  _productAlreadyInCart(item) {
    return this.items.find((currentItem) =>
      cartItemEquality(currentItem, item)
    );
  }

  _addToExistingPrice(existingItem, cents) {
    existingItem.basePriceInCents = existingItem.basePriceInCents + cents;
  }

  _addToExistingQuantity(existingItem, quantity) {
    existingItem.quantity = existingItem.quantity + quantity;
  }

  /*
  Return the count of (base) products exist in the cart, regardless of variant
  selections.
  */
  _parentProductCount(productId) {
    const parentProducts = this.items.filter((item) => {
      if (item.isParentItem && item.productId === productId) return item;
    });
    return parentProducts.length;
  }
}

export default Cart;
