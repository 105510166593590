import { storage } from '@Lib/StorageWrangler';
import { writable, derived } from 'svelte/store';
import Cart from '@Models/Cart';
import Customer from '@Models/Customer';
import Address from '@Models/Address';
import CreditCard from '@Models/CreditCard';
import Payment from '@Models/Payment';
import Fees from '@Models/Fees';
import Order from '@Models/Order';
const currentOrder = new Order();
const makeNewModelInstance = (model, initialParams) => {
    if (!initialParams)
        return new model();
    return new model(initialParams);
};
const createPersistableStore = (key, model, initialParams) => {
    const { subscribe, set, update } = writable(makeNewModelInstance(model, initialParams));
    const load = () => {
        // Load from json and build the model instance.
        update((currentObj) => {
            const attribs = storage.getAttribsByKey(key);
            currentObj = model.deserialize(attribs);
            return currentObj;
        });
    };
    // Load the object from the browserStorage on initialization.
    load();
    return {
        subscribe,
        update,
        set,
        load,
        // Use the class serializer methods for each models to serialize the
        // models and save to the proper storage.
        save: () => {
            // Jsonify and save the model instance.
            update((currentObj) => {
                const attribs = model.serialize(currentObj);
                storage.setAttribsOnKey(key, attribs);
                return currentObj;
            });
        },
        reset: () => {
            // reset via set() back to the model of this store
            set(makeNewModelInstance(model, initialParams));
            storage.setAttribsOnKey(key, {});
        },
    };
};
const currentPanelKey = writable();
const cart = createPersistableStore('cart', Cart);
const customer = createPersistableStore('customer', Customer);
const shippingAddress = createPersistableStore('shippingAddress', Address);
const billingAddress = createPersistableStore('billingAddress', Address, {
    type: 'billing',
});
const creditCard = createPersistableStore('creditCard', CreditCard);
const payment = createPersistableStore('payment', Payment);
const fees = createPersistableStore('fees', Fees);
const order = derived([cart, customer, shippingAddress, billingAddress, creditCard, payment, fees], ([$cart, $customer, $shippingAddress, $billingAddress, $creditCard, $payment, $fees,]) => {
    const attribs = {
        cart: $cart,
        customer: $customer,
        shippingAddress: $shippingAddress,
        billingAddress: $billingAddress,
        creditCard: $creditCard,
        payment: $payment,
        fees: $fees,
    };
    Object.assign(currentOrder, attribs);
    return currentOrder;
});
const clearAllStores = () => {
    [
        cart,
        customer,
        shippingAddress,
        billingAddress,
        creditCard,
        payment,
        fees,
    ].forEach((store) => {
        store.reset();
    });
    storage.clearCart();
    storage.clearCheckout();
};
export { currentPanelKey, clearAllStores, cart, customer, shippingAddress, billingAddress, creditCard, payment, fees, order, };
