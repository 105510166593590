<script>
  export let variantSelection;
</script>

<div class="tw-text-base tw-whitespace-nowrap">
  {#if variantSelection.variantName}
    {variantSelection.variantName}:
  {/if}
  {variantSelection.name}
</div>
