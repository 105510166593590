<script>
  import { order } from '@/store';
  import TaxDisplay from '@Pages/cart/TaxDisplay.svelte';
  import ShippingFeesDisplay from '@Pages/cart/ShippingFeesDisplay.svelte';
</script>

{#if $order.hasItems}
  <div class="cgn-cart-footer" data-testid="cartFooter">
    <ShippingFeesDisplay />
    <TaxDisplay />
    <div class="tw-flex tw-justify-between tw-font-bold tw-pb-2">
      <span class="tw-font-bold tw-text-lg">Total:</span>
      <span class="tw-font-bold tw-text-lg" data-testid="grandTotal"
        >{$order.grandTotalAsCurrency}</span
      >
    </div>
  </div>
{/if}
