import './base.scss';
import App from './App.svelte';
import 'regenerator-runtime/runtime';
const CARTIGAN_LOADED_EVENT_NAME = 'CartiganLoaded';
const cgnLoadedEvent = new Event(CARTIGAN_LOADED_EVENT_NAME);
const startApp = () => {
    new App({
        target: document.body,
    });
    document.dispatchEvent(cgnLoadedEvent);
};
const waitUntilReady = (fn) => {
    document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
            fn();
        }
    });
};
if (document.readyState === 'complete') {
    /*
    It looks like the readyState is already 'complete'.  We can just start
    the app at this point.
    */
    startApp();
}
else {
    // We need to set an event listener to wait for the document.readyState to
    // be 'complete'
    waitUntilReady(startApp);
}
