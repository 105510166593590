import { convertToCurrency, simpleSerialization } from '@Lib/utils';

class VariantSelection {
  constructor({ variantName, name, priceInCents, variantId, optionId }) {
    this.variantName = variantName;
    this.name = name;
    this.priceInCents = priceInCents;
    this.variantId = variantId;
    this.optionId = optionId;
  }

  static serialize(variantSelection) {
    return simpleSerialization(variantSelection, [
      'variantName',
      'name',
      'priceInCents',
      'variantId',
      'optionId',
    ]);
  }

  static deserialize(attribs = {}) {
    return new VariantSelection(attribs);
  }

  get priceInCents() {
    return this._priceInCents || 0;
  }

  set priceInCents(val) {
    this._priceInCents = ~~val;
  }

  get price() {
    return this.priceInCents / 100;
  }

  get priceAsCurrency() {
    return convertToCurrency(this.price);
  }

  get uniqueId() {
    return `${this.variantId}-${this.optionId}`;
  }
}

export default VariantSelection;
