import { BasicValidation } from '@Lib/basicValidators';
import { simpleSerialization } from '@Lib/utils';
import AppConfig from '@Lib/AppConfig';

// Payment represents the credit card token returned from the payment
// processor, as well as any other information that might be needed
// to pass on to the API later for the Order placement.
class Payment extends BasicValidation {
  constructor(attribs = {}) {
    super();
    this.creditCard = attribs.creditCard;
    this.token = attribs.token;
    this.processor = attribs.processor;
    this.forceRenewToken = false;
    this._billingName = attribs.billingName;
    this._postalCode = attribs.postalCode;
    this.errors = [];
  }

  static serialize(payment) {
    return simpleSerialization(payment, [
      'token',
      'processor',
      'billingName',
      'postalCode',
    ]);
  }

  static deserialize(attribs = {}) {
    return new Payment(attribs);
  }

  get isChargeable() {
    return !!this.token;
  }

  validateAll() {
    this.clearErrors();
    this.validatePresenceOf('token');
  }

  isValid() {
    this.validateAll();
    return !this.errors.length;
  }

  get deducedProcessor() {
    return this.processor || AppConfig().processor;
  }

  //TODO: Is this still used?
  get shouldFetchToken() {
    return !this.isChargeable || this.forceRenewToken;
  }

  get billingName() {
    return this._billingName || this.creditCard?.billingName;
  }

  get postalCode() {
    return this._postalCode || this.creditCard?.postalCode;
  }
}

export default Payment;
