<script>
  import { statesAsObjects } from '@/lib/usStates';
  import CartiganInput from '@Components/Input/CartiganInput.svelte';
  import ErrorDisplay from './ErrorDisplay.svelte';

  export let address;

  export let errors;


</script>

<div class="tw-mt-8" id="shipping-address-form">
  <h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">
    Shipping address
  </h3>
  <ErrorDisplay {errors} testId='addressErrorList' />
  <div class="cgn-address-form">
    <div>
      <CartiganInput
        id="recipientName"
        placeholder="Name"
        bind:value={address.recipientName}
        data-testid="recipientName"
        fullWidth
        class="tw-bg-red"
      />
    </div>
    <div>
      <CartiganInput
        class="tw-mt-3"
        id="address"
        placeholder="Street Address"
        bind:value={address.address}
        data-testid="address"
        autocomplete="address-line1"
        fullWidth
      />
      <CartiganInput
        class="tw-mt-3"
        id="address2"
        placeholder="Apt or Suite Number"
        autocomplete="address-line2"
        bind:value={address.address2}
        data-testid="address2"
      />
    </div>
    <div class="tw-flex tw-flex-wrap tw-gap-x-3">
      <div class="tw-mt-3 tw-flex-auto">
        <CartiganInput
          class="tw-w-full"
          id="city"
          autocomplete="address-level2"
          bind:value={address.city}
          placeholder="City"
          data-testid="city"
        />
      </div>
      <div class="tw-mt-3 tw-flex-auto">
        <select
          class="tw-w-full tw-border tw-border-blue-50 tw-px-4 tw-py-4 tw-text-base sm:tw-text-lg tw-text-blue-50"
          aria-label="Select your state"
          bind:value={address.state}
          autocomplete="address-level1"
        >
          <option value="">Select a State</option>
          {#each statesAsObjects as state}
            <option value={state.abbreviation}>
              {state.name}
            </option>
          {/each}
        </select>
      </div>
    </div>
    <CartiganInput
      class="tw-w-6/12 tw-mt-3"
      id="zipCode"
      autocomplete="postal-code"
      bind:value={address.zipCode}
      placeholder="ZIP Code"
      data-testid="zipCode"
      inputMode="numeric"
    />
  </div>
</div>

<style>
  .cgn-address-form select {
    min-height: 3.3em;
  }

  :global(#autocomplete-state-input) {
    padding: 1.25rem;
  }
  :global(#shipping-address-form .autocomplete-list) {
    max-height: 200px;
  }
  :global(#shipping-address-form .state-autocomplete-div) {
    height: 3.8rem;
  }
</style>
