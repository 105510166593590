var CartNames;
(function (CartNames) {
    CartNames["cart"] = "Cart";
    CartNames["shoppingBag"] = "Shopping Bag";
    CartNames["basket"] = "Basket";
})(CartNames || (CartNames = {}));
var PaymentProcessors;
(function (PaymentProcessors) {
    PaymentProcessors["Stripe"] = "Stripe";
    PaymentProcessors["Square"] = "Square";
})(PaymentProcessors || (PaymentProcessors = {}));
const DEFAULT_API_BASE_URL = 'https://rest-api.cartigan.com/api/v1';
const DEFAULT_TOP_POSITION = 0;
const CONFIG = {
    cartiganId: '',
    apiBaseUrl: DEFAULT_API_BASE_URL,
    top: DEFAULT_TOP_POSITION,
    processor: '',
    development: window.location.hostname === 'localhost',
    fulfillmentType: 'shipping',
    forShipping: function () {
        return this.fulfillmentType === 'shipping';
    },
    forDelivery: function () {
        return this.fulfillmentType === 'delivery';
    },
    forPickup: function () {
        return this.fulfillmentType === 'pickup';
    },
    cartName: CartNames.shoppingBag,
    checkoutImageUrl: '',
    checkout: {},
};
window.CartiganJS = {
    configure: (options) => Object.assign(CONFIG, options),
};
export default () => CONFIG;
