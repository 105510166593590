<script>
  import { onMount } from 'svelte';
  import { currentPanelKey, fees, order } from '@/store';
  import TaxEstimateService from '@/services/TaxEstimateService';
  import ErrorDisplay from '@Pages/checkout/ErrorDisplay.svelte';
  import { PANELS_KEYS } from '@Lib/panelManager';

  const showTaxOverride = $currentPanelKey === PANELS_KEYS.CheckoutPayment;
  const showTax = showTaxOverride || $order.hasTax;
  const recalculateTax = showTaxOverride;

  let errors;

  onMount(async () => {
    if (!recalculateTax) return;

    const estimator = new TaxEstimateService();
    const response = await estimator.forOrder($order);
    if (response.success) {
      $fees.tax = response.body.amount_to_collect;
      fees.save();
    } else {
      errors = response.errors;
    }
  });
</script>

{#if showTax}
  <div
    class="tw-flex tw-justify-between tw-pb-2 tw-text-base"
    data-testid="cartShipping"
  >
    <span>Sales tax</span>
    <span>{$fees.taxAsCurrency}</span>
    <ErrorDisplay mesgPrefix={'Tax Estimate Failed: '} {errors} />
  </div>
{/if}
