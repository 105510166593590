<script>
  import { currentPanelKey, order } from '@/store';
  import { PANELS_KEYS } from '@Lib/panelManager';

  const showShippingOverride = $currentPanelKey === PANELS_KEYS.CheckoutPayment;
  const showShipping = showShippingOverride || $order.hasShipping;
</script>

{#if showShipping}
  <div
    class="tw-flex tw-justify-between tw-pb-2 tw-text-base"
    data-testid="cartShipping"
  >
    <span>Shipping</span>
    <span>FREE</span>
  </div>
{/if}
