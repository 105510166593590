<script lang="ts">
  import { currentPanelKey } from '../store';
  import { getPanel } from '@Lib/panelManager';
  import CartPanel from './cart/CartPanel.svelte';
  import CreditCardManager from './checkout/Payment/CreditCardManager.svelte';
  import CheckoutInfoAndAddress from './checkout/CheckoutInfoAndAddress.svelte';
  import ThankYou from './checkout/thankYou/ThankYou.svelte';

  const DISPLAY_COMPONENTS = {
    CartPanel,
    CheckoutInfoAndAddress,
    CreditCardManager,
    ThankYou,
  };

  let currentPanel = null;

  const getCurrentComponent = () =>
    currentPanel ? DISPLAY_COMPONENTS[currentPanel.component] : null;

  $: currentPanel = getPanel($currentPanelKey);
</script>

{#if currentPanel}
  <div class="tw-flex tw-flex-1 tw-flex-col" data-testid="checkoutPanel">
    <svelte:component this={getCurrentComponent()} />
  </div>
{/if}
