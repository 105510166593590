import AppConfig from './AppConfig';
import Address from '../models/Address';

const SQ_SANDBOX_ID = 'sandbox-sq0idb-eLMcp8VOz-bLCiVSK9Zvgg';
const SQ_PRODUCTION_ID = 'sq0idp--LYS-1aE4fKUNhDBKT6vSQ';

const squareApplicationId = () => {
  return AppConfig().development ? SQ_SANDBOX_ID : SQ_PRODUCTION_ID;
};

const billingPostalCode = () => {
  const address = new Address({ type: 'billing' });
  return address.zipCode;
};

// These params are for the Square Payment Form global var that is created when
// SquareCreditCardForm component is mounted
const squarePaymentFormParams = (squareVarName) => {
  return {
    applicationId: squareApplicationId(),
    inputClass: 'cc-input',
    autoBuild: false,
    // Initialize the credit card inputTargets
    cardNumber: {
      elementId: 'ccNumber',
      placeholder: '• • • •  • • • •  • • • •  • • • •',
    },
    cvv: {
      elementId: 'ccCode',
      placeholder: 'CVV',
    },
    expirationDate: {
      elementId: 'ccExpiration',
      placeholder: 'MM/YY',
    },
    postalCode: {
      elementId: 'ccPostalCode',
      placeholder: '12345',
    },

    // Customize the CSS for SqPaymentForm iframe elements
    inputStyles: [
      {
        boxShadow: '5px 10px 0px 0px rgb(255,255,255)',
        fontFamily: 'Arial',
        fontSize: '15px',
        fontWeight: '100',
        padding: '2px',
      },
    ],

    // SqPaymentForm callback functions
    callbacks: {
      /*
       * callback function: cardNonceResponseReceived
       * Triggered when: SqPaymentForm completes a card nonce request
       */
      cardNonceResponseReceived: function (errors, nonce, cardData) {
        if (errors) {
          // Log errors from nonce generation to the Javascript console
          // eslint-disable-next-line no-console
          console.log('Encountered errors:');
          errors.forEach(function (error) {
            // eslint-disable-next-line no-console
            console.log('  ' + error.message);
          });

          const errorsEvent = new CustomEvent('nonceCreation', {
            detail: {
              errors: errors,
            },
          });
          document.dispatchEvent(errorsEvent);

          return;
        }

        const nonceCreatedEvent = new CustomEvent('nonceCreation', {
          detail: {
            nonce: nonce,
            cardData: cardData,
          },
        });
        document.dispatchEvent(nonceCreatedEvent);
      },

      /*
       * callback function: unsupportedBrowserDetected
       * Triggered when: the page loads and an unsupported browser is detected
       */
      unsupportedBrowserDetected: function () {
        alert(
          'Sorry! You must upgrade your browser to be able to take ' +
            'advantage of the features of this cart'
        );
      },

      // Sets the postal code from sessionStorage previous screens
      paymentFormLoaded: function () {
        window[squareVarName].setPostalCode(billingPostalCode());
      },

      // More available callbacks listed here:
      // https://developer.squareup.com/docs/api/paymentform#_callbackfunctions_detail
    },
  };
};

export default squarePaymentFormParams;
