import AppConfig from '@Lib/AppConfig';

class CartiganServiceBase {
  constructor() {
    this.errors;
  }

  async makeRequest({ url, method, body }) {
    method = method || 'POST';
    body = body || {};
    const apiResponse = await fetch(this._fullUrl(url), {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${AppConfig().cartiganId}`,
      },
      body: JSON.stringify(body),
    });

    const responseBody = await this._getResponseBody(apiResponse);
    return {
      errors: this.errors,
      success: apiResponse.ok,
      status: apiResponse.status,
      body: responseBody,
    };
  }

  _fullUrl(relativeUrl) {
    return [AppConfig().apiBaseUrl, relativeUrl].filter(Boolean).join('/');
  }

  async _parseResponseBody(response) {
    try {
      return await response.json();
    } catch (e) {
      return { errors: [e.toString()] };
    }
  }

  async _getResponseBody(response) {
    const body = await this._parseResponseBody(response);
    if (!response.ok) {
      this.errors = body.errors;
    }
    return body;
  }
}

export default CartiganServiceBase;
