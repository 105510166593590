<script>
  import { cart } from '@/store';
  import CartDisplay from '@Pages/cart/CartDisplay.svelte';
  import CartFooter from './CartFooter.svelte';
  import { PANELS_KEYS, setCompletedAndAdvance } from '@Lib/panelManager';

  const advanceToCheckout = () => setCompletedAndAdvance(PANELS_KEYS.Cart);
</script>

<div
  class="cgn-cart-display tw-flex tw-flex-col tw-flex-1"
  data-testid="cartDisplay"
>
  <CartDisplay cart={$cart} />
  {#if $cart.hasItems}
    <div class="tw-px-8 sm:tw-px-10 tw-py-4 tw-border-t tw-border-blue-50">
      <CartFooter />
      <div class="cgn-cart-actions tw-text-center ">
        <button
          class="tw-border tw-border-blue-50 tw-px-16 tw-py-3 tw-rounded-full	tw-text-lg tw-font-bold tw-bg-white tw-w-full"
          on:click={advanceToCheckout}
          disabled={$cart.isEmpty}
        >
          Checkout
        </button>
      </div>
    </div>
  {/if}
</div>
