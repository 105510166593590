import { convertToCurrency, simpleSerialization } from '@Lib/utils';
import VariantSelection from '@Models/VariantSelection';
import { objectMerger } from '@Lib/utils';

class CartItem {
  constructor(attribs = {}) {
    this._productId = attribs.productId;
    this.name = attribs.name;
    this.description = attribs.description;
    this.basePriceInCents = attribs.basePriceInCents;
    this.quantity = attribs.quantity;
    this.imageUrl = attribs.imageUrl;
    this.variantSelections = attribs.variantSelections || [];
    this.hasAdjustablePrice = attribs.hasAdjustablePrice;
    this.dependentOf = attribs.dependentOf;
    this.additionalCheckoutHTML = attribs.additionalCheckoutHTML;
  }

  /*
  Merge multiple CartItems into a single CartItem.
  */
  static mergeItems(cartItems) {
    const serializedItems = cartItems.map(CartItem.serialize);

    // This is necessary since quantity of a CartItem will return 1 if not present
    const highestQuantity = Math.max(
      ...serializedItems.map((item) => item.quantity)
    );
    const mergedObject = objectMerger(serializedItems);
    mergedObject.quantity = highestQuantity;
    return CartItem.deserialize(mergedObject);
  }

  static serialize(cartItem) {
    const attribs = simpleSerialization(cartItem, [
      'productId',
      'name',
      'description',
      'basePriceInCents',
      'imageUrl',
      'quantity',
      'hasAdjustablePrice',
      'dependentOf',
      'additionalCheckoutHTML'
    ]);

    if (cartItem.hasVariantSelections) {
      attribs.variantSelections = cartItem.variantSelections.map(
        (variantSelection) => VariantSelection.serialize(variantSelection)
      );
    }
    return attribs;
  }

  static deserialize(attribs = {}) {
    if (attribs.variantSelections) {
      // Convert the variantSelectionss attributes into VariantSelections
      attribs.variantSelections = attribs.variantSelections.map(
        (selectionAttribs) => {
          return VariantSelection.deserialize(selectionAttribs);
        }
      );
    }
    return new CartItem(attribs);
  }

  get productId() {
    // eslint-disable-next-line getter-return
    if (!this._productId) return undefined;

    return this._productId.toString().trim();
  }

  get uniqueId() {
    return [this.productId, this.variantSelectionUniqueId]
      .filter(Boolean)
      .join('-');
  }

  get priceModifierInCentsTotal() {
    if (!this.variantSelections) return 0;

    return this.variantSelections.reduce((sum, selection) => {
      return sum + selection.priceInCents;
    }, 0);
  }

  get priceInCents() {
    return this.basePriceInCents + this.priceModifierInCentsTotal;
  }

  get price() {
    return this.priceInCents / 100;
  }

  get priceAsCurrency() {
    return convertToCurrency(this.price);
  }

  get quantity() {
    if (this.hasAdjustablePrice) return 1;

    return this._quantity ? Number.parseInt(this._quantity) : 1;
  }

  set quantity(val) {
    this._quantity = ~~val;
  }

  get basePriceInCents() {
    return this._basePriceInCents;
  }

  set basePriceInCents(val) {
    // converts to integer via bitwise negation
    // https://cwestblog.com/2017/06/27/javascript-convert-to-integer/
    this._basePriceInCents = ~~val;
  }

  get mayAdjustQuantity() {
    return !this.hasAdjustablePrice;
  }

  get hasImage() {
    return !!this.imageUrl;
  }

  get variantSelectionUniqueId() {
    if (!this.variantSelections.length) return undefined;

    const uniqueIds = this.variantSelections
      .map((selection) => {
        return selection.uniqueId;
      })
      .sort();

    return uniqueIds.join('-');
  }

  get hasVariantSelections() {
    return !!(this.variantSelections && this.variantSelections.length);
  }

  get subtotalInCents() {
    return this.priceInCents * this.quantity;
  }

  get subtotal() {
    return this.subtotalInCents / 100;
  }

  get subtotalAsCurrency() {
    return convertToCurrency(this.subtotal);
  }

  get isDependentItem() {
    return !!this.dependentOf;
  }

  get isParentItem() {
    return !this.isDependentItem;
  }

  /*
    Should be deleted, based on criteria of the item to be deleted.
    @param {string} uniqueId - the unique id of the product to be deleted
    @param {string} productId - the product id of the product to be deleted
    @param {bool} multiple - if there are multiple parent products, this will
    be true. This effects how/when/if dependent products are deleted.
  */
  shouldBeDeleted(uniqueId, productId, multiple = false) {
    return (
      this._shouldBeDeletedDirectly(uniqueId) ||
      this._shouldBeDeletedIndirectly(productId, multiple)
    );
  }

  /*
    Determine if the product should have its price zeroed out,
    instead of deleted.
    @param {string} uniqueId - the unique Id of the product to be deleted
    @param {string} productId - the product id of the product to be deleted
  */
  shouldBeZeroedOut(uniqueId) {
    if (this.uniqueId !== uniqueId) return false;

    if (this.hasAdjustablePrice && this.isDependentItem) {
      return true;
    }
  }

  zeroOutPrice() {
    this.basePriceInCents = 0;
  }

  isDependentOf(productId) {
    return this.dependentOf && this.dependentOf === productId;
  }

  // Determine if this should be deleted directly. i.e. a user clicked on this
  // item specifically to delete it directly.
  _shouldBeDeletedDirectly(uniqueId) {
    if (this.uniqueId === uniqueId) {
      // No product that is marked as dependent AND has an
      // adjustable price may be deleted directly.
      if (this.isDependentItem && this.hasAdjustablePrice) return false;

      // Otherwise delete this item
      return true;
    }

    return false;
  }

  // Determine if this should be deleted indirectly. i.e. a user clicked to
  // delete this item's parent.
  _shouldBeDeletedIndirectly(productId, multiple = false) {
    // If this productId represents the parent product of this product, then
    // delete this dependent product.
    // However, if there is more than one (multiple) parent products in the cart
    // of this dependent product, then do not delete.
    if (this.isDependentOf(productId) && !multiple) return true;

    return false;
  }
}

export default CartItem;
