<script>
  import AppConfig from '@Lib/AppConfig';
  import { fly } from 'svelte/transition';
  import { onMount } from 'svelte';
  import { cart, currentPanelKey } from './store';
  import CartItem from '@Models/CartItem';
  import PaymentProcessorSetup from '@Lib/PaymentProcessorSetup';
  import { scrapeProductAndAddons } from '@Lib/ProductDataScraper';
  import PanelDisplay from '@Pages/PanelDisplay.svelte';
  import {
    PANELS_KEYS,
    PANELS_STYLE_CONFIG,
    closeDisplay,
  } from '@Lib/panelManager';
  import { isMobile } from '@Lib/utils/screen';

  const DEFAULT_WIDTH = '540px';
  const DEFAULT_BACKGROUND = 'tw-bg-white';

  const APP_CONTAINER_ID = 'cgn-app-container';

  let additionalHeight,
    slideOutHeight,
    slideOutHeightPlusBuffer,
    windowHeight,
    scrollYPos = 0;

  const toggleCart = (event) => {
    if ($currentPanelKey === PANELS_KEYS.Cart) {
      closeDisplay();
    } else {
      $currentPanelKey = PANELS_KEYS.Cart;
    }
    event.preventDefault();
  };

  const addToCart = (event) => {
    const cartItems = scrapeProductAndAddons(event.target);
    cartItems.forEach((item) => $cart.addToCart(item));
    $currentPanelKey = PANELS_KEYS.Cart;
    cart.save();
    event.preventDefault();
  };

  /*
  Used by the site owner/developer, via CartiganJS.addToCart.
  This will make valid CartItems, whose attributes are passed in via
  javascript.

  Takes in either an object literal of properties, or an array of such
  object literals.
  */
  const addToCartProgrammatically = (attribs = {}) => {
    if (!Array.isArray(attribs)) attribs = [attribs];

    attribs.forEach((itemAttribs) => {
      const item = CartItem.deserialize(itemAttribs);
      $cart.addToCart(item);
    });
    $currentPanelKey = PANELS_KEYS.Cart;
    cart.save();
  };

  // Map of data-cgn values and what functions they trigger.
  const CGN_FUNCTIONS = {
    add: addToCart,
    view: toggleCart,
  };

  const isInternalClick = (target) => {
    // Is internal to Cartigan if the click target was inside the app container.
    return !!target.closest(`#${APP_CONTAINER_ID}`);
  };

  // Find the closest ancestor element with the `data-cgn-cart` attribute.
  const closestCgnTarget = (target) => {
    return target.closest('[data-cgn-cart]');
  };

  // Relevant if it possesses the cgn-cart data attribute.
  const isRelevantExternalClick = (target) => {
    return !!closestCgnTarget(target);
  };

  // If the click is external to Cartigan, and is not an external target that
  // Cartigan would listen to (e.g. add to cart, then we should close
  // the cart display.
  const shouldCloseDisplay = (target) => {
    return !(isInternalClick(target) || isRelevantExternalClick(target));
  };

  // Determine if user clicked on a button outside our app
  const handleClick = (event) => {
    const target = event.target;
    if (shouldCloseDisplay(target)) {
      closeDisplay();
      return;
    }

    if (isRelevantExternalClick(target)) {
      const relevantTarget = closestCgnTarget(target);
      CGN_FUNCTIONS[relevantTarget.dataset.cgnCart](event);
    }
  };

  const bodyHeight = () => {
    return document.body.offsetHeight;
  };

  const { processor, top } = AppConfig();
  /*
   `addToCart` function is made available in the `window.CartiganJS` variable.
   TODO: Make this happen in `AppConfig.ts`
  */
  window.CartiganJS.addToCart = addToCartProgrammatically;


  onMount(async () => {
    const paymentProcessorSetup = new PaymentProcessorSetup(processor); // TODO move this to another place?
    await paymentProcessorSetup.setup();
  });

  // Recalculate the displayable cart quantity on load.
  $cart.recalculateDisplayableQuantity();

  // ---- Reactive elements here -----
  $: slideOutHeightPlusBuffer = slideOutHeight + 80;
  $: {
    additionalHeight = Math.max(slideOutHeightPlusBuffer - bodyHeight(), 0);
  }

  let currentPanelWidth,
    currentPanelHeight,
    currentPanelTop,
    currentPanelBackground;
  $: {
    const styles = PANELS_STYLE_CONFIG[$currentPanelKey];
    currentPanelWidth = styles?.width || DEFAULT_WIDTH;
    currentPanelHeight = '100vh';
    currentPanelBackground = styles?.backgroundColor || DEFAULT_BACKGROUND;
    currentPanelTop = isMobile() ? 0 : top;
  }

  const getPanelStyle = (panelKey) => {
    return panelKey === PANELS_KEYS.Cart
      ? 'tw-fixed tw-overflow-auto tw-bg-yellow-50'
      : 'tw-fixed tw-h-screen tw-overflow-scroll sm:tw-min-h-screen';
  };

  let panelContainerStyles;
  $: {
    panelContainerStyles = getPanelStyle($currentPanelKey);
  }
</script>

<svelte:window bind:innerHeight={windowHeight} bind:scrollY={scrollYPos} />

<svelte:body on:click|capture={handleClick} />

<div
  id="cgn-app-container"
  class="cgn-app-container"
  data-testid="appContainer"
>
  {#if $currentPanelKey}
    <div
      id="cgn-slideout"
      class={`tw-flex tw-max-w-full ${panelContainerStyles}`}
      style="width: {currentPanelWidth}; height: {currentPanelHeight}; top: {currentPanelTop}px"
      transition:fly={{ duration: 250, x: 1000 }}
    >
      <div
        class="cgn-slideout-inner {currentPanelBackground} tw-flex tw-flex-col tw-flex-1"
      >
        <PanelDisplay />
      </div>
    </div>
  {/if}
</div>

<div id="cgn-cart-spacer" style="height: {additionalHeight}px;">
  <!--  Used to create space that keeps the body of -->
  <!-- the page long enough to accommodate the cart -->
</div>

<style global lang="postcss">
  @tailwind components;
  @tailwind utilities;

  :global(.cgn-titlecase) {
    text-transform: capitalize;
  }

  :global(.infoBox) {
    background-color: #d0dae380;
  }

  :global(ul.unstyled) {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  :global(.cgn-btn) {
    background-color: #555;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    font-size: 13px;
    font-weight: bold;
    padding: 6px 12px;
    text-decoration: none;
    text-shadow: 0px 1px 0px #5b8a3c;
  }

  :global(.cgn-panel-title) {
    font-weight: bold;
    text-align: center;
    color: #333;
    margin: 3px 0;
    padding: 0;
  }

  :global(.cgn-checkout-next) {
    display: block;
    text-align: center;
    background: #2cb12c;
    color: white;
    font-size: 1.1em;
    line-height: 1.4em;
  }

  :global(.cgn-alert) {
    border-radius: 4px;
    margin: 8px 0;
    padding: 8px;
  }

  :global(.cgn-alert-error) {
    color: darkred;
    border-radius: 4px;
    padding: 10px;
    background: lightpink;
  }

  #cgn-slideout {
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    width: 540px;
    border: solid 1px #9d9615;
  }

  .cgn-slideout-inner {
    position: relative;
    width: 100%;
  }

  :global(.cgn-loader) {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #022548;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  :global(.loader-text) {
    font-weight: bold;
    font-size: 0.8em;
  }

  /* Safari */

  :global(input:disabled) {
    color: #333;
    -webkit-text-fill-color: #333;
    opacity: 1; /* required on iOS */
  }

  @-webkit-keyframes -global-spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes -global-spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


</style>
