<script>
  import { customer, shippingAddress } from 'src/store';
  import { goBack } from '@Lib/panelManager';
</script>

<h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">
  Your information
</h3>
<div class="tw-flex tw-p-3 infoBox">
  <div class="tw-flex tw-flex-col">
    <span>{$customer.email}</span>
    <span>{$shippingAddress.recipientName}</span>
    <span>{$shippingAddress.address}</span>
    <span>{$shippingAddress.cityStateZip}</span>
  </div>
  <button class="tw-ml-auto tw-self-baseline tw-underline" on:click={goBack}>
    Edit
  </button>
</div>
