import { stripSpacesAndDashes } from './utils';

const CC_REGEXES = [
  {
    name: 'Visa',
    regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
  },
  {
    name: 'MasterCard',
    regex:
      /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/,
  },
  {
    name: 'American Express',
    regex: /^3[47][0-9]{13}$/,
  },
  {
    name: 'Diners Club',
    regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  },
  {
    name: 'Discover',
    regex: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  },
  {
    name: 'JCB',
    regex: /^(?:2131|1800|35\d{3})\d{11}$/,
  },
];

// Returns the name of the credit card brand, based on the number
const creditCardBrandFromNumber = (number) => {
  if (!number) {
    return;
  }

  const found = CC_REGEXES.find((obj) => {
    return stripSpacesAndDashes(number).match(obj.regex);
  });
  return found && found.name;
};

export { creditCardBrandFromNumber };
