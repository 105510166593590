import AppConfig from './AppConfig';
import { storage } from './StorageWrangler';

const squareJSHost = () => {
  return AppConfig().development ? 'js.squareupsandbox.com' : 'js.squareup.com';
};

const squareJSUrl = () => {
  return `https://${squareJSHost()}/v2/paymentform`;
};

const squarePaymentFormJSExists = () => {
  return document.head.querySelector(`script[src="${squareJSUrl()}"]`);
};

const addSquarePaymentFormJS = () => {
  if (squarePaymentFormJSExists()) {
    return;
  }

  const scriptTag = document.createElement('script');
  scriptTag.src = squareJSUrl();
  document.head.append(scriptTag);
};

// Sets the payment processor based on what cartigan-api responds back with
// If the payment processor also requires additional setup, in the page,
// or additional scripts need to be added to the document, this will do
// that as well. The processor can also be manually set in the
// CartiganJS configuration with the key `paymentProcessor`
class PaymentProcessorSetup {
  constructor(localConfigProcessor) {
    this.localConfigProcessor = localConfigProcessor;
  }

  async fetchProcessor() {
    if (this.localConfigProcessor) {
      return this.localConfigProcessor;
    }

    //TODO: get the processor from Cartigan API
    // This will likely be a separate module
  }

  // Fetches the current payemnt processor from the session. If not
  // available, will fetch the processor from Cartigan API and
  // set the processor in the session
  async currentProcessor() {
    const current = storage.processor;
    if (current) {
      storage.processor = await this.fetchProcessor();
    }
    return storage.processor;
  }

  async setup() {
    const processorResult = await this.currentProcessor();
    if (processorResult == 'square') {
      addSquarePaymentFormJS();
    }
  }
}

export default PaymentProcessorSetup;
