import { twoDigitYear, simpleSerialization } from '@Lib/utils';
import { BasicValidation } from '@Lib/basicValidators';
import creditCardValidators from '@Lib/creditCardValidators';
import { creditCardBrandFromNumber } from '@Lib/creditCardNumberParser';

class CreditCard extends BasicValidation {
  constructor(attribs = {}) {
    super();
    const {
      number,
      expirationMonth,
      expirationYear,
      verificationCode,
      last4,
      brand,
      billingName,
      postalCode,
    } = attribs;

    Object.assign(this, {
      ...creditCardValidators,
      number,
      expirationMonth,
      _expirationYear: expirationYear,
      verificationCode,
      last4,
      brand,
      billingName,
      postalCode,
    });
    this.errors = [];
  }

  static serialize(creditCard) {
    return simpleSerialization(creditCard, [
      'expirationMonth',
      'expirationYear',
      'brand',
      'last4',
      'billingName',
      'postalCode',
    ]);
  }

  static deserialize(attribs = {}) {
    return new CreditCard(attribs);
  }

  // Only set the last two digits of the year
  set expirationYear(val) {
    if(!val) return;

    this._expirationYear = val.slice(-2)
  }

  get expirationYear() {
    return this._expirationYear
  }

  validateExpirationMonth() {
    const intMonth = this.expirationMonth && parseInt(this.expirationMonth);
    if (intMonth >= 1 && intMonth <= 12) {
      return true;
    }
    this.addError({ name: 'expirationMonth', mesg: 'is not a valid month' });
  }

  validateExpirationYear() {
    const yearStr = this.expirationYear // && this.expirationYear.toString().substr(-2);
    const year = yearStr && parseInt(yearStr);
    const currentYear = parseInt(twoDigitYear());

    if (year && year >= currentYear) {
      return true;
    }

    this.addError({
      name: 'expirationYear',
      mesg: 'must be a valid expiration year in the future',
    });
  }

  // If any error is present, will return false, otherwise, true.
  get isErrorFree() {
    return !this.errors.length;
  }

  validateAll() {
    this.clearErrors();
    this.validateCreditCardNumber('number');
    this.validatePresenceOf('billingName');
    this.validateLengthOf('postalCode', { minimum: 5, maximum: 10 });
    this.validateNumericalityOf('verificationCode');
    this.validateLengthOf('verificationCode', { minimum: 3, maximum: 4 });
    this.validateExpirationMonth();
    this.validateExpirationYear();
  }

  isValid(skipValidation = false) {
    if (skipValidation) return true;

    this.validateAll();
    return this.isErrorFree;
  }

  reset() {
    this.number = null;
    this.expirationMonth = null;
    this.expirationYear = null;
    this.verificationCode = null;
  }

  get brand() {
    return this._brand || this.deducedBrand;
  }

  set brand(val) {
    this._brand = val;
  }

  get last4() {
    return this._last4 || this.deducedLast4;
  }

  set last4(val) {
    this._last4 = val;
  }

  get deducedBrand() {
    return this.number && creditCardBrandFromNumber(this.number);
  }

  get deducedLast4() {
    return this.number && this.number.toString().slice(-4);
  }

  get displayableBrand() {
    return this.brand && this.brand.toString().toUpperCase();
  }

  get displayableInfo() {
    return [this.displayableBrand, 'ending in', this.last4].join(' ');
  }

  get displayableExpiration() {
    return `${this.expirationMonth}/${this.expirationYear}`;
  }

  get expiration() {
    return this.displayableExpiration; //Used for validation Mapper later..
  }
}

export default CreditCard;
