
class ValidationMapper {
  /*
  @param (Object) model: the model
  @param (String) prop: the property to map and validate
  */
  constructor({model, prop}) {
    this.model = model;
    this.prop = prop;
    this.elem = null;
  }

  validationError() {
    return this.model.errors.find((error) => error.name === this.prop);
  }

  isBlank() {
    return !this.elem.value
  }

  isInvalid() {
    return !!this.validationError();
  }

  get modelValue() {
    return this.model[this.prop]
  }
}

export default ValidationMapper
