// creates the params necessary to place the Order. These params are
// generated from the stores (session and local storage)

import AppConfig from './AppConfig';

const variantSelectionParamsForItem = (item) => {
  return item.variantSelections.map((selection) => ({
    name: selection.name,
    price: { amount: selection.priceInCents },
    variant_name: selection.variantName,
    variant_id: selection.variantId,
    option_id: selection.optionId,
  }));
};

class OrderApiParams {
  constructor(order) {
    this.order = order;
  }

  get cart() {
    return this.order.cart;
  }

  get customer() {
    return this.order.customer;
  }

  get payment() {
    return this.order.payment;
  }

  get shippingAddress() {
    return this.order.shippingAddress;
  }

  get billingAddress() {
    return this.order.billingAddress;
  }

  get fees() {
    return this.order.fees;
  }

  get customerParams() {
    return { email: this.customer.email };
  }

  get cartItems() {
    return this.cart.items;
  }

  get itemParams() {
    return this.cartItems.map((item) => {
      return {
        product_id: item.productId,
        name: item.name,
        description: item.description,
        image_url: item.imageUrl,
        quantity: item.quantity,
        price: { amount: item.priceInCents },
        variant_selections: variantSelectionParamsForItem(item),
      };
    });
  }

  get paymentParams() {
    const params = {
      token: this.payment.token,
      charge_total: { amount: this.order.grandTotalInCents },
      processor: this.payment.processor || undefined,
      billing_name: this.payment.billingName,
      postal_code: this.payment.postalCode,
    };
    return params;
  }

  addressParams(address) {
    const params = {
      address: address.address,
      address2: address.address2,
      city: address.city,
      state: address.state,
      postal_code: address.zipCode,
    };
    if (address.isShippingAddress) {
      params.recipient_name = address.recipientName;
    } else {
      params.billing_name = address.billingName;
    }
    return params;
  }

  get feeParams() {
    return {
      tax: { amount: this.order.fees.taxInCents },
      shipping: { amount: this.order.shippingInCents },
    };
  }

  get orderParams() {
    return {
      fulfillment_type: AppConfig().fulfillmentType,
      customer: this.customerParams,
      items: this.itemParams,
      payment: this.paymentParams,
      fees: this.feeParams,
      shipping_address: this.addressParams(this.shippingAddress),
    };
  }
}

const orderParams = (order) => {
  const paramsMaker = new OrderApiParams(order);
  return paramsMaker.orderParams;
};

export default orderParams;
