
// Find if any scripts have the domain `static.cartigan.[any tld]`
const currentScript = document.querySelector("script[src*=\"static.cartigan.\"]");

/*
  Return the script url if it exists. This could be a local url (/) or the
  FQDN of a `static.cartigan` script location.
  For development mode, and when the currentScript is not found, we return '/'.
  This will work for all:
  - External sites that incorporate our script via <script> tag
  - localhost (development) sites
  - `static.cartigan` sites where the app is built.
*/
const getScriptURL = () => {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development' || !currentScript) return '/';

  const url = new URL(currentScript.src);
  return `${url.origin}/`;
};

const getAssetURL = (path) => {
  return `${getScriptURL()}${path}`;
};

export {getAssetURL}
