<script>
  import { convertToCurrency } from '@Lib/utils';
  import { storage } from '@Lib/StorageWrangler';
  import OrderDisplay from './OrderDisplay.svelte';
  import Order from '@Models/Order';
  import CheckoutHeader from '../common/CheckoutHeader.svelte';
  import CartItemDisplay from '../../cart/CartItemDisplay.svelte';

  // Build the Order from the PlacedOrder attributes in storage.
  const buildPlacedOrder = () => {
    const order = Order.deserialize(storage.placedOrderObj);
    order.placed = true;
    return order;
  };

  let order = buildPlacedOrder();
</script>

<div class="tw-flex tw-flex-col tw-bg-white">
  <CheckoutHeader />
  <div
    class="tw-px-8 sm:tw-px-8 tw-py-8 tw-border-t tw-border-r tw-border-blue-50 tw-w-full tw-text-center tw-flex tw-justify-center tw-text-blue-50"
    data-testid="congrats-header"
  >
    <div class="sm:tw-w-6/12">
      <h1 class="tw-text-4xl tw-font-bold">Yay!</h1>
      <p class="tw-py-3 tw-text-xl">
        Your order #{order.orderId} has been placed 💕
      </p>
      <p class="tw-text-left">
        Your order is being made with love and will ship in the next 3-5
        days. As soon as it does, we’ll send you an email with tracking
        information.
      </p>
      <p class="tw-text-left tw-my-3">
        If you have any questions about your purchase, please email us at
        support@swagleft.com.
      </p>
    </div>
  </div>
  <div
    data-testid="order-details"
    class="tw-py-8 tw-flex tw-border-t tw-border-blue-50 tw-flex-col sm:tw-flex-row"
  >
    <OrderDisplay {order} />

    <div class="tw-px-8 sm:tw-px-8 tw-py-8 sm:tw-w-6/12">
      <h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">
        Order #{order.orderId} Summary
      </h3>
      {#each order.cart.items as item (item.uniqueId)}
        <CartItemDisplay {item} readOnly />
      {/each}
      <div
        class="tw-py-3 tw-border-t tw-border-blue-50"
        data-testid="cartFooter"
      >
        <div
          class="tw-flex tw-justify-between tw-pb-2 tw-text-base"
          data-testid="cartShipping"
        >
          <span>Shipping</span>
          <span>FREE</span>
        </div>
        <div
          class="tw-flex tw-justify-between tw-pb-2 tw-text-base"
          data-testid="cartShipping"
        >
          <span>Sales tax</span>
          <span>{order.fees.taxAsCurrency}</span>
        </div>
        <div
          class="tw-flex tw-justify-between tw-font-bold tw-pb-2 tw-text-base"
        >
          <span class="tw-font-bold tw-text-xl">Total:</span>
          <span class="tw-font-bold tw-text-xl" data-testid="grandTotal"
            >{convertToCurrency(order.grandTotal)}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
