<script>
  import { getAssetURL } from '@Lib/utils/url';

  import AppConfig from '@Lib/AppConfig';
  import { closeDisplay } from '@Lib/panelManager';
  export let cart;
</script>

<div
  class="tw-flex tw-items-center tw-font-bold tw-px-8 sm:tw-pl-10 sm:tw-pr-8 tw-py-3 bag-container"
>
  <div class="tw-relative tw-flex tw-items-center">
    <img src={`${getAssetURL('bag.svg')}`} alt="bag" />
    <span class="tw-absolute bag-number">{cart.displayableQuantity}</span>
  </div>
  <p class="tw-pl-2 tw-text-3xl">Your {AppConfig().cartName}</p>
  <img
    src={`${getAssetURL('close-icon.svg')}`}
    class="close-button tw-text-blue-50 tw-relative tw-ml-auto"
    alt="close"
    title="close"
    aria-label="close"
    on:click={closeDisplay}
  />
</div>

<style>
  .bag-number {
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
  .bag-container {
    border-bottom: 1px solid #022548;
  }
  img.close-button {
    cursor: pointer;
    height: 11px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
