// Sorted unique IDs for an item
const uniqueIdsForItem = (item) => {
  return item.variantSelections.map((selection) => selection.uniqueId).sort();
};

class CartItemEqualityTest {
  constructor(item1, item2) {
    this.item1 = item1;
    this.item2 = item2;
  }

  get productIdMatch() {
    return this.item1.productId === this.item2.productId;
  }

  get variantLengthMatch() {
    return (
      this.item1.variantSelections.length ===
      this.item2.variantSelections.length
    );
  }

  get variantSelectionMatch() {
    const idString1 = JSON.stringify(uniqueIdsForItem(this.item1));
    const idString2 = JSON.stringify(uniqueIdsForItem(this.item2));

    return idString1 === idString2;
  }

  get variantMatch() {
    return this.variantLengthMatch && this.variantSelectionMatch;
  }

  get match() {
    return this.productIdMatch && this.variantMatch;
  }
}

const cartItemEquality = (item1, item2) => {
  const tester = new CartItemEqualityTest(item1, item2);
  return tester.match;
};

export default cartItemEquality;
