<script>
  import ErrorDisplay from './ErrorDisplay.svelte';
  import CartiganInput from '@Components/Input/CartiganInput.svelte';

  export let customer;
  export let errors;

</script>

<div>
  <h3 class="tw-text-blue-50 tw-text-lg tw-pb-3 tw-font-bold">
    Contact Information
  </h3>
  <ErrorDisplay {errors} testId='customerErrorList' />
  <CartiganInput
    placeholder="Email Address"
    id="customer-email"
    autocomplete="email"
    bind:value={customer.email}
    data-testid="customerEmail"
    variant="outlined"
    inputMode="email"
    fullWidth
  />
  <CartiganInput
    class="tw-mt-3"
    placeholder="Phone number"
    id="customer-phone"
    autocomplete="tel"
    inputMode="tel"
    bind:value={customer.phone}
    data-testid="customerPhone"
    variant="outlined"
    fullWidth
  />
</div>
