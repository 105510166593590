const trimVal = (val) => {
  if (val) {
    return val.toString().trim();
  }
};

const PHONE_REGEX = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

export class BasicValidation {

  validatePresenceOf(prop) {
    const val = trimVal(this[prop]);
    if (val) {
      return true;
    }
    this.addError({ name: prop, mesg: 'cannot be blank' });
  }
  clearErrors() {
    this.errors.length = 0;
  }

  addError(error) {
    this.errors.push(error);
  }

  validatePhoneNumber(prop) {
    const val = trimVal(this[prop]);

    if (val && val.match(PHONE_REGEX)) {
      return true;
    }
    this.addError({ name: prop, mesg: 'is not a valid phone number' });
  }

  validateNumericalityOf(prop) {
    const val = trimVal(this[prop]);

    if (val && val.match(/^\d+$/)) {
      return true;
    }
    this.addError({ name: prop, mesg: 'is not a valid number' });
  }

  validateLengthOf(prop, { minimum, maximum }) {
    const val = trimVal(this[prop]);
    if (!val) return;
    // If no minimum or maximum lengths were provided, just verify not blank.
    if (!minimum && !maximum) return this.validatePresenceOf(prop);

    if (
      (maximum && val.length > maximum) ||
      (minimum && val.length < minimum)
    ) {
      this.addError({
        name: prop,
        mesg: 'does not meet the meet the length requirements',
      });
    }
  }
}

export default {};
