import US_STATES_DATA from '../data/us_states.json';

const stateObjectMap = () => {
  const states = [];
  for (const abbreviation in US_STATES_DATA) {
    states.push({
      abbreviation: abbreviation,
      name: US_STATES_DATA[abbreviation],
    });
  }
  return states;
};

const statesAsObjects = stateObjectMap();

const fullStateNameFor = (abbreviation) => {
  const found = statesAsObjects.find((obj) => {
    return obj.abbreviation == abbreviation;
  });
  return found && found.name;
};

export { statesAsObjects, fullStateNameFor };
