<script lang="ts">
  import { cart } from '@/store';
  import VariantDisplay from './VariantDisplay.svelte';
  import ItemPriceField from '@Pages/cart/ItemPriceField.svelte';
  import { isElementVisible } from '@/lib/utils/screen';
  import { getAssetURL } from '@Lib/utils/url.js';

  export let item;
  export let readOnly = false;

  const itemIsDeletable = !(item.hasAdjustablePrice && item.isDependentItem);
  const showDeleteBtn = !readOnly && itemIsDeletable;
  const showPriceEach = !item.hasAdjustablePrice;
  const showReadOnlyQuantity = readOnly && !item.hasAdjustablePrice;
  const showQuantityControls = !readOnly && item.mayAdjustQuantity;

  const incrementQuantity = () => {
    $cart.changeItemQuantity(item, item.quantity + 1);
    cart.save();
  };
  const decrementQuantity = () => {
    $cart.changeItemQuantity(item, item.quantity - 1);
    cart.save();
  };
  const deleteItem = () => {
    $cart.deleteItem(item);
    cart.save();
  };

  let ref;

  export function focus() {
    if (!isElementVisible(ref, window)) {
      ref.scrollIntoView?.();
    }
  }
</script>

<div class="cgn-cart-row tw-py-3 tw-flex tw-relative" bind:this={ref}>
  <div class="cgn-cart-thumbnail">
    <img
      src={item.imageUrl ||
        `${getAssetURL('images/cart-item-placeholder.png')}`}
      alt={item.name}
    />
  </div>

  <div class="tw-pl-3.5 tw-flex tw-flex-col tw-justify-between">
    <div
      class="cgn-cart-product-name tw-font-bold tw-text-base"
      data-testid="productName"
    >
      {item.name}
    </div>
    <div class="tw-flex tw-pt-1 sm:tw-pt-0">
      {#if showQuantityControls}
        <div
          class="tw-border tw-border-black tw-rounded-md cgn-quantity-controls tw-flex tw-items-center"
        >
          <button
            class="cgn-quantity-btn tw-text-xs tw-w-4 tw-border-0 hover:tw-bg-blue-50 hover:tw-text-white tw-h-full tw-w-5 tw-rounded-r-none"
            data-testid="decrementer"
            on:click={decrementQuantity}
          >
            &minus;
          </button>
          <div class="tw-border-r tw-border-black tw-h-full" />
          <div
            data-testid="quantity"
            class="tw-w-6 tw-text-center tw-flex tw-justify-center"
          >
            <span class="cgn-quantity tw-font-bold tw-text-xs"
              >{item.quantity}</span
            >
          </div>
          <div class="tw-border-r tw-border-black tw-h-full" />
          <button
            data-testid="incrementer"
            class="cgn-quantity-btn tw-text-xs tw-w-4 tw-border-0 hover:tw-bg-blue-50 hover:tw-text-white tw-h-full tw-w-5 tw-rounded-l-none"
            on:click={incrementQuantity}
          >
            &plus;
          </button>
        </div>
      {:else if showReadOnlyQuantity}
        <span class="tw-text-base">Qty: {item.quantity}</span>
      {/if}
      {#if showPriceEach}
        <div class="tw-flex tw-flex-col sm:tw-flex-row tw-text-base">
          <span class="tw-pl-5">
            {item.priceAsCurrency} each
          </span>
          <div
            class="tw-flex tw-flex-col tw-pl-5 sm:tw-pl-7 tw-pt-1 sm:tw-pt-0"
          >
            {#if item.hasVariantSelections}
              {#each item.variantSelections as variantSelection (variantSelection.uniqueId)}
                <VariantDisplay {variantSelection} />
              {/each}
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>

  <div class="cgn-cart-product-price tw-ml-auto">
    <ItemPriceField {item} {readOnly} />
  </div>

  {#if showDeleteBtn}
    <div
      class="cgn-other-controls tw-absolute tw-text-white tw-cursor-pointer tw-text-center tw-bg-blue-50 tw-rounded-full tw-text-yellow-50 tw-p-1 tw-leading-3"
    >
      <img
        src={`${getAssetURL('remove-circle.svg')}`}
        alt="bag"
        data-testid="deleteItem"
        on:click={deleteItem}
      />
    </div>
  {/if}
</div>

<style lang="scss">
  @mixin unselectable {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
  }

  .cgn-cart-thumbnail {
    position: relative;
    min-width: 60px;
    height: 60px;
    overflow: hidden;
    vertical-align: middle;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 60px;
      width: 60px;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .cgn-quantity-controls {
    height: 25px;
  }

  .cgn-other-controls {
    top: 7px;
    left: -7px;
    width: 20px;
    height: 20px;
  }
</style>
