import { convertToCurrency, moneyInCents } from '@Lib/utils';

class Fees {
  constructor(attribs = {}) {
    this.taxInCents = attribs.taxInCents || 0;
    this.shippingInCents = attribs.shippingInCents || 0;
  }

  static serialize(fees) {
    return {
      taxInCents: fees.taxInCents,
      shippingInCents: fees.shippingInCents, // placeholder for now.
    };
  }

  static deserialize(attribs = {}) {
    return new Fees(attribs);
  }

  set tax(floatVal) {
    this.taxInCents = moneyInCents(floatVal);
  }

  get tax() {
    return this.taxInCents / 100;
  }

  get taxAsCurrency() {
    return convertToCurrency(this.tax);
  }
}

export default Fees;
