<script>
  export let showSpinner = false;
</script>

{#if showSpinner}
  <div id="credit-card-spinner" class="spinner-area">
    <div class="loader-text">Validating Card...</div>
    <div class="cgn-loader" />
  </div>
{/if}
