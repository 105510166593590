// Will scrape relevant customer info upon initialization and store it in
// the `info` property.
import { camelize } from '@Lib/utils';

const CGN_CUSTOMER_ATTRIBS = {
  name: 'cgn-customer-name',
  email: 'cgn-customer-email',
};

class CustomerInfoScraper {
  constructor() {
    this.info = {};
    this._scrape();
  }

  _getValueFromDataAttribute(attribute) {
    const elem = document.querySelector(`[data-${attribute}]`);
    if (!elem) return;

    return elem.dataset[attribute];
  }

  _scrape() {
    // Sets the `info` object with any properties it scraped from the page.
    for (const [prop, dataName] of Object.entries(CGN_CUSTOMER_ATTRIBS)) {
      const elem = document.querySelector(`[data-${dataName}]`);
      if (!elem) continue;

      const datasetName = camelize(dataName);
      this.info[prop] = elem.dataset[datasetName];
    }
  }
}

export default CustomerInfoScraper;
