<script>
  import { toSentence } from '@Lib/utils';

  export let errors = [];
  export let mesgPrefix = null;
  export let testId = 'errorList'
</script>

{#if errors?.length}
  <ul class="cgn-error-list" data-testid="{testId}">
    {#each errors as error}
      <li class="cgn-error-item">
        <span role="alert">
          {#if mesgPrefix}
            {mesgPrefix}
          {/if}
          {#if typeof error === 'string'}
            {toSentence(error)}
          {:else}
            <span class="cgn-titlecase">{toSentence(error.name)}</span>
            {error.mesg}
          {/if}
        </span>
      </li>
    {/each}
  </ul>
{/if}

<style lang="scss">
  ul.cgn-error-list {
    margin: 5px 0 10px 0;
    padding: 10px;
    background: lightpink;
    border-radius: 6px;
    border: solid 1px lightpink;

    li {
      margin-left: 1em;
      color: darkred;
    }
  }
</style>
