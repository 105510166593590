<script>
  import { setContext } from 'svelte';
  import { payment } from 'src/store';
  import StripeCreditCardForm from './StripeCreditCardForm.svelte';
  import SquareCreditCardForm from './SquareCreditCardForm.svelte';
  import Checkout from '@Pages/checkout/Checkout.svelte';
  import CheckoutInfoSummary from '@Pages/checkout/Payment/CheckoutInfoSummary.svelte';

  const creditCardComponent =
    $payment.processor === 'square'
      ? SquareCreditCardForm
      : StripeCreditCardForm;

  // TODO: re-visit
  setContext('creditCardContext', {
    toggleEdit: () => {
      $payment.forceRenewToken = true;
      $payment = $payment;
    },
  });
</script>

<Checkout>
  <CheckoutInfoSummary />
  <div data-testid="creditCardFormArea" class="tw-mt-3 tw-relative">
    <svelte:component this={creditCardComponent} />
  </div>
</Checkout>
