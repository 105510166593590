const TOKEN_VARNAME = 'CGN_creditCardToken';

// When a payment processor returns a token, this will set it and
// retrieve it from sessionStorage

export default {
  set token(val) {
    window.sessionStorage.setItem(TOKEN_VARNAME, val);
  },

  get token() {
    return window.sessionStorage.getItem(TOKEN_VARNAME);
  },

  delete() {
    window.sessionStorage.removeItem(TOKEN_VARNAME);
  },
};
